<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <template v-if="action === SubmitStatus.Success">
            <v-card-title v-t="'ml_114661'">Notice</v-card-title>
            <v-card-text>
              <p v-t="'ml_114662'">
                eVCR has been submitted successfully. System will go to home page in 3
                seconds.
              </p>
            </v-card-text>
          </template>
          <template v-else-if="action === SubmitStatus.Failure">
            <v-card-title>Warning</v-card-title>
            <v-card-text>
              <p>eVCR has not been submitted successfully.</p>
              <div class="red-error">{{ errorMsg }}</div>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" @click="goPre" v-t="'ml_114638'"> Back </v-btn>
            </v-card-actions>
          </template>
          <template v-else-if="action === SubmitStatus.Processing">
            <v-card-title>Information</v-card-title>
            <v-card-text>
              <p>submitting is in process.</p>
            </v-card-text>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { ImgeApiMode, PageName, SubmitStatus } from "@/models/enums";
import { ConsoleLog, Timeout, Text } from "@/utility";
import Vue from "vue";
import { GoBack, GoHome, GoSummary, RemoveStorageEvcr } from "@/utility/ui-utility";
import { StoreAction, StoreMutation } from "@/store/store-mapping";
export default Vue.extend({
  components: {
    // RecordOverviewItem,
    // HomePage,
  },
  props: {
    action: String,
  },
  data() {
    return {
      //   submitStatus: SubmitStatus,
      SubmitStatus,
      errorMsg: "",
    };
  },
  computed: {},
  methods: {
    Text,
    GoBack,
    goPre() {
      GoSummary(this.$router);
    },
    async onOkay() {
      await Timeout(3000);
      GoHome(this.$router, false);
    },
    async submit() {
      //submit request
      this.errorMsg = "";
      const self = this as any;
      let isOk = false;
      ConsoleLog("eVCR sumbit at question page");
      isOk = await self.$store.dispatch(StoreAction.emPostNewEvcr, {
        imageApiMode: ImgeApiMode.One,
        longitude: "2",
      });
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.errorMsg = this.$store.state.evcrModule.errorMsg;
      }
      if (isOk) {
        self.$store.commit(StoreMutation.emResetEvcr);
        RemoveStorageEvcr();
        await this.onOkay();
      }
      return isOk;
    },
  },
  watch: {
    action: {
      immediate: true,
      handler: async function (newValue: string) {
        ConsoleLog({ "watch.submitStatus": "Current vaules:" + newValue });
        switch (newValue) {
          case SubmitStatus.Processing.toString():
            // await this.submit();
            break;
          case SubmitStatus.Success.toString():
            await this.onOkay();
            break;
        }
      },
      deep: false,
    },
  },
});
</script>

<style></style>
