<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title v-t="'ml_114658'" class="color-red">Caution!</v-card-title>
          <v-card-text>
            <p v-t="'ml_114659'" class="color-red">
              You have critical defects on the eVCR form, please don’t start the trip (if
              you are on pre-trip) and contact your supervisor for the guidance of next
              step.
            </p>
          </v-card-text>
          <v-card-text v-if="errorMsg.length > 0">
            <!-- <p>eVCR has not been submitted successfully.</p> -->
            <div class="red-error">{{ errorMsg }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="GoBack($router)" v-t="'ml_114660'">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onOkay" v-t="'ml_114654'"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { ImgeApiMode, PageName, SubmitStatus } from "@/models/enums";
import Vue from "vue";
import { GoBack, RemoveStorageEvcr } from "@/utility/ui-utility";
import { ConsoleLog, Text } from "@/utility";
import { StoreAction, StoreMutation } from "@/store/store-mapping";
export default Vue.extend({
  components: {
    // RecordOverviewItem,
    // HomePage,
  },
  data() {
    return {
      errorMsg: "",
    };
  },
  computed: {},
  methods: {
    Text,
    GoBack,
    async onOkay() {
      const self = this as any;
      self.errorMsg = "";
      
      //const isGood = await this.submit();
      //if (isGood) {
      //  this.$router.push({
      //    name: PageName.RecordQuestionSubmitResult,
      //    params: { action: SubmitStatus.Success },
      //  });
      //}

      this.$router.push({
        name: PageName.RecordQuestionSubmitSign,
      });
    },
    async submit() {
      //submit request
      const self = this as any;
      let isOk = false;
      ConsoleLog("eVCR sumbit at question page");
      isOk = await self.$store.dispatch(StoreAction.emPostNewEvcr, {
        imageApiMode: ImgeApiMode.One,
        longitude: "2",
      });
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.errorMsg = this.$store.state.evcrModule.errorMsg;
      }
      if (isOk) {
        this.$store.commit(StoreMutation.emResetEvcr);
        RemoveStorageEvcr();
      }
      return isOk;
    },
  },
});
</script>

<style></style>
