<template>
  <v-row>
    <template v-if="showAsLabel">
      <v-card-text>
        <div v-text="label" />
        <div v-text="value" />
      </v-card-text>
    </template>
    <template v-else>
      <v-text-field
        :value="value"
        :counter="counter"
        :rules="rules"
        :label="label"
        :readonly="readonly"
        v-on:input="onChanging"
        :type="type"
        v-mask="'' + mask + ''"
        :placeholder="placeholder.length > 0 ? placeholder : label"
      >
              <!-- v-on:change="onUserChanging" -->
        <template #label v-if="required">
          <span class="red-error"><strong>* </strong></span>{{ label }}
        </template>
      </v-text-field>
    </template>
  </v-row>
</template>

<script lang="ts">
import { ConsoleLog } from "@/utility";
import Vue from "vue";

export default Vue.extend({
  props: {
    value: String || Number,
    label: String,
    counter: Number,
    rules: [],
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    mask: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    type: { type: String, default: "text" },
  },
  computed: {},
  methods: {
    onChanging(value: string) {
      ConsoleLog({ "FormInput.onChanging": value });
      this.$emit("input", value);
      this.$emit("userChange", value);
    },
    onUserChanging(value: any) {
      ConsoleLog({ "FormInput.onUserChanging": value });
      this.$emit("userChange", value);
    },
  },
});
</script>
