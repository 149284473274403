<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title v-t="'ml_114601'">Trip Info</v-card-title>
          <v-btn color="primary" @click="onNext" v-if="IsLocalMode()">
            Next
          </v-btn>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <form-radio-group
                v-model="tripInfo.tripType"
                :rules="rules.tripType"
                :label="Text('ml_114897', 'Trip Type')"
                required
                :input="tripInfo.tripType"
                :items="tripInfo.tripTypeItems"
                @userChange="(newValue) => updateTripType(newValue)"
              ></form-radio-group>

              <!-- <form-input
                v-model="tripInfo.lbsTripId"
                :rules="ruleTripId"
                :label="tripIdLabel"
                required
                :mask="tripIdMask"
                :placeholder="tripIdPlaceHolder"
                ref="lbsTripId"
                @userChange="(newValue) => updateTripIdInfo(newValue)"
              >
              </form-input> -->
              <!-- <v-text-field
                v-model="tripInfo.lbsTripId"
              ></v-text-field>
              <v-text-field
                v-model="tripInfo.pgTripId"
              ></v-text-field> -->
              <form-input
                v-model="tripInfo.lbsTripId"
                :rules="rules.lbsTripIdRule"
                :label="Text('ml_114625', 'LBShell Trip ID')"
                :required="showLbTripIdInput && (!showOtherTripIdInput)"
                v-show="showLbTripIdInput && (!showOtherTripIdInput)"
                :mask="STR_TRIP_ID_MASK_LB"
                :placeholder="STR_TRIP_ID_PLACE_HOLDER_LB"
                @userChange="(newValue) => updateLbTripIdInfo(newValue)"
              >
              </form-input>
              <form-input
                v-model="tripInfo.pgTripId"
                :rules="rules.pgTripIdRule"
                :label="Text('ml_114902', 'PG Trip ID')"
                :required="(!showLbTripIdInput) && (!showOtherTripIdInput)"
                v-show="(!showLbTripIdInput) && (!showOtherTripIdInput)"
                :mask="STR_TRIP_ID_MASK_PG"
                :placeholder="STR_TRIP_ID_PLACE_HOLDER_PG"
                @userChange="(newValue) => updateTripIdInfo(newValue)"
              >
              </form-input>
              <form-radio-group
                v-model="tripInfo.prePost"
                :rules="rules.pre"
                :label="Text('ml_114627', 'Pre/Post')"
                required
                :input="tripInfo.prePost"
                :items="tripInfo.prePostItems"
                @userChange="updateTripInfo(false, 'prePost');"
              ></form-radio-group>
              <!-- @userChange="updatePrePost()" 
              <v-radio-group
                v-model="tripInfo.prePost"
                row
                :rules="rules.pre"
                label="Pre/Post"
              >
                <v-radio label="Pre" value="PR"></v-radio>
                <v-radio label="Post" value="PO"></v-radio>
              </v-radio-group> -->
              <!-- <v-select
                :items="nearbyPlant"
                v-model="tripInfo.plantId"
                label="Plant ID"
                :rules="rules.plant"
              ></v-select> -->
              <div style="margin-bottom: 10px;">           
              <!-- <form-autocomplete
                :items="nearbyPlant"
                v-if="false"
                v-model="tripInfo.plantId"
                :label="Text('ml_114628', 'Plant ID')"
                required
                itemText="plantIdName"
                itemValue="plantId"
                :userFilter="onUserFilter"
                @userChange="(newValue) => onPlantChange(newValue, tripInfo)"
                :rules="rules.plant"
              ></form-autocomplete> -->
              <form-autocomplete
                :items="nearbyPlant"
                v-show="showLbPlant"
                v-model="tripInfo.customPlantId"
                :label="Text('ml_116553', 'Arrival DT')"
                :required="showLbPlant"
                itemText="plantIdName"
                itemValue="plantId"
                :userFilter="onUserFilter"
                :key="tripInfo.customPlantId"
                @userChange="(newValue) => onPlantChange(newValue, tripInfo)"
                :rules="rules.customPlant"
              ></form-autocomplete>
              <form-autocomplete
                :items="nearbyPlant"
                v-show="showPgPlant"
                v-model="tripInfo.customPlantId"
                :label="showDT"
                :required="showPgPlant"
                itemText="plantIdName"
                itemValue="plantId"
                :userFilter="onUserFilter"
                @userChange="(newValue) => onPlantChange(newValue, tripInfo)"
                :rules="rules.customPlant"
              ></form-autocomplete> 
              </div>
              <!-- :rules="rules.plant" -->
              <!-- <template v-if="IsDebug() === true">
                <p>
                  {{
                    "Location: " +
                    customerLocation.latitude +
                    ", " +
                    customerLocation.longitude
                  }}
                </p>
              </template> -->
              <div class="red-error">{{ locationMsg }}</div>
              <form-input
                v-model="tripInfo.driver1Name"
                :rules="rules.driver1"
                :label="Text('ml_114629', 'Driver')"
                :readonly="true"
                required
              >
              </form-input>
              <!-- <form-input
                v-model="tripInfo.driver2"
                :label="Text('ml_114630', 'Driver 2')"
                @userChange="updateTripInfo"
              >
              </form-input> -->

              <v-card-actions class="v-card__actions_padding_left0  v-card__actions_padding_bottom_0">
                <v-row>
                  <v-col>
                <!-- <form-input
                  v-model="tripInfo.tractor"
                  :rules="rules.tractor"
                  @userChange="updateTripInfo"
                  :label="Text('ml_114631', 'Tractor/Rigid/Truck')"
                  required
                ></form-input> -->
                <form-input
                  v-model="tripInfo.tractor"
                  v-if="tripInfo.isTractorThirdParty"
                  :rules="rules.tractor"
                  @userChange="updateTripInfo"
                  :label="Text('ml_114631', 'Tractor/Rigid/Truck')"
                  required
                ></form-input>
                <form-autocomplete
                  :items="vehicles(SapVehcileCategory.Tractor)"
                  v-model="tripInfo.tractor"
                  v-if="!tripInfo.isTractorThirdParty"
                  :label="Text('ml_114631', 'Tractor/Rigid/Truck')"
                  required
                  itemText="vehicleId"
                  itemValue="vehicleId"
                  :userFilter="
                    (item, queryText, itemText) =>
                      onUserVehicleFilter(
                        item,
                        queryText,
                        itemText,
                        SapVehcileCategory.Tractor
                      )
                  "
                  @userChange="
                    (newValue) =>
                      onVehicleChange(
                        newValue,
                        tripInfo,
                        SapVehcileCategory.Tractor
                      )
                  "
                  :rules="rules.tractor"
                ></form-autocomplete>
                </v-col>
                <v-col cols="5" class="v-checkbox_padding_right_0">
                <v-checkbox class="v-checkbox_margin_top_0"
                v-model="tripInfo.isTractorThirdParty"
                :label="Text('ml_116414', '3rd Party')"
                @change="(newValue) => onTractorThirdPartyStatusChange(newValue,tripInfo)"
                >
                </v-checkbox>
                </v-col>
                
                <v-col cols="3" class="qr-scaner_padding_left_0">
                <qr-scaner
                  formId="formIdTractor"
                  :initialValue="tripInfo.tractor"
                  :labelText="Text('ml_114631', 'Tractor/Rigid/Truck')"
                  @handleChange="handleTractorChange"
                />
                </v-col>
                
                </v-row>
              </v-card-actions>
              <!-- <v-checkbox
                v-model="tripInfo.thirdParty"
                :label="`3rd Party Tractor`"
                @change="updateTripInfo"
              ></v-checkbox> -->
              <v-card-actions class="pb-5 v-card__actions_padding_top_0">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="10">
                <form-select
                :items="getTractorCategoryItems()"
                v-model="tripInfo.tractor3rdPartyVehType"
                v-if="tripInfo.isTractorThirdParty"
                :label="Text('ml_116415', 'Vehicle Type')"
                required
                :rules="rules.vehicleType"
                >
                </form-select>
                </v-col>
                </v-row>
              </v-card-actions>

              <v-card-actions class="v-card__actions_padding_left0  v-card__actions_padding_bottom_0">
                <v-row>
                  <v-col>
                <form-input
                  v-model="tripInfo.chassis"
                  v-if="tripInfo.isChassisThirdParty"
                  :rules="rules.chassis"
                  @userChange="updateTripInfo"
                  :label="Text('ml_114633', 'Chassis')"
                  required
                ></form-input>
                <form-autocomplete
                  :showAsLabel="showChassisAutoCompleteAsLabel()"
                  :items="vehicles(SapVehcileCategory.Chassis)"
                  v-model="tripInfo.chassis"
                  v-if="!tripInfo.isChassisThirdParty"
                  :label="Text('ml_114633', 'Chassis')"
                  itemText="vehicleId"
                  itemValue="vehicleId"
                  :userFilter="
                    (item, queryText, itemText) =>
                      onUserVehicleFilter(
                        item,
                        queryText,
                        itemText,
                        SapVehcileCategory.Chassis
                      )
                  "
                  @userChange="
                    (newValue) =>
                      onVehicleChange(
                        newValue,
                        tripInfo,
                        SapVehcileCategory.Chassis
                      )
                  "
                ></form-autocomplete>
                </v-col>
                
                <v-col cols="5" class="v-checkbox_padding_right_0">
                <v-checkbox class="v-checkbox_margin_top_0"
                v-model="tripInfo.isChassisThirdParty"
                :label="Text('ml_116414', '3rd Party')"
                @change="(newValue) => onChassisThirdPartyStatusChange(newValue,tripInfo)"
                >
                </v-checkbox>
                </v-col>

                <v-col cols="3" class="qr-scaner_padding_left_0">
                <qr-scaner
                  formId="formIdChassis"
                  :initialValue="tripInfo.chassis"
                  @handleChange="handleChassisChange"
                  :labelText="Text('ml_114633', 'Chassis')"
                />
                </v-col>
                </v-row>
              </v-card-actions>
              <v-card-actions class="pb-5 v-card__actions_padding_top_0">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="10">
                <form-select
                :items="getChassisCategoryItems()"
                v-model="tripInfo.chassis3rdPartyVehType"
                v-if="tripInfo.isChassisThirdParty"
                :label="Text('ml_116415', 'Vehicle Type')"
                required
                :rules="rules.chassisVehicleType"
                >
                </form-select>
                </v-col>
                </v-row>
              </v-card-actions>

              <v-card-actions class="v-card__actions_padding_left0 v-card__actions_padding_bottom_0">
                <v-row>
                  <v-col>
                    <form-input
                      v-model="tripInfo.vessel"
                      v-if="tripInfo.isVesselThirdParty"
                      :rules="rules.vessel"
                      @userChange="updateTripInfo"
                      :label="Text('ml_114634', 'Vessel')"
                      required
                    ></form-input>
                <form-autocomplete
                  :showAsLabel="showVesselAutoCompleteAsLabel()"
                  :items="vehicles(SapVehcileCategory.Vessel)"
                  v-model="tripInfo.vessel"
                  v-if="!tripInfo.isVesselThirdParty"
                  :label="Text('ml_114634', 'Vessel')"
                  itemText="vesselId"
                  itemValue="vesselId"
                  :userFilter="
                    (item, queryText, itemText) =>
                      onUserVesselFilter(
                        item,
                        queryText,
                        itemText,
                        SapVehcileCategory.Vessel
                      )
                  "
                  @userChange="
                    (newValue) =>
                      onVehicleChange(
                        newValue,
                        tripInfo,
                        SapVehcileCategory.Vessel
                      )
                  "
                ></form-autocomplete>
                  </v-col>
                
                
                
                <v-col cols="5" class="v-checkbox_padding_right_0">
                  <v-checkbox class="v-checkbox_margin_top_0"
                    v-model="tripInfo.isVesselThirdParty"
                    :label="Text('ml_116414', '3rd Party')"
                    @change="(newValue) => onVesselThirdPartyStatusChange(newValue,tripInfo)"
                  >
                  </v-checkbox>
                </v-col>
                
                
                <v-col cols="3" class="qr-scaner_padding_left_0">
                  <qr-scaner 
                  formId="formIdVessel"
                  :initialValue="tripInfo.vessel"
                  @handleChange="handleVesselChange"
                  :labelText="Text('ml_114634', 'Vessel')"
                />
                </v-col>
                
              </v-row>
              </v-card-actions>
              <v-card-actions class="pb-5 v-card__actions_padding_top_0">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="10">
                    <form-select
                      :items="getVesselCategoryItems()"
                      v-model="tripInfo.vessel3rdPartyVehType"
                      v-if="tripInfo.isVesselThirdParty"
                      :label="Text('ml_116415', 'Vehicle Type')"
                      required
                      :rules="rules.vesselVehicleType"
                    ></form-select>
                  </v-col>
                </v-row>
                
              </v-card-actions>
              <form-input
                v-model="tripInfo.odometer"
                :rules="rules.odometer"
                :label="Text('ml_114635', 'Odometer')"
                required
                :type="this.numberType"
                @userChange="updateTripInfo(false, 'odometer')"
              ></form-input>
              <v-row>
                <v-menu
                  ref="menuDate"
                  v-model="menuDate"
                  :close-on-content-click="true"
                  :return-value.sync="tripInfo.date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tripInfo.date"
                      :label="Text('ml_114636', 'Date')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.date"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-if="menuDate"
                    v-model="tripInfo.date"
                    :max="maxDate()"
                    no-title
                    scrollable
                    @click:date="$refs.menuDate.save(tripInfo.date)"
                    @change="updateTripInfo(false, 'date')"
                  >
                    <v-spacer></v-spacer>
                    >
                  </v-date-picker>
                </v-menu>
              </v-row>
              <v-row>
                <v-menu
                  ref="menuTime"
                  v-model="menuTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="tripInfo.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tripInfo.time"
                      :label="Text('ml_114637', 'Time')"
                      prepend-icon="mdi-clock-time-four-outline"
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.time"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menuTime"
                    v-model="tripInfo.time"
                    :max="maxTime()"
                    full-width
                    @click:minute="$refs.menuTime.save(tripInfo.time)"
                    @change="updateTripInfo(false, 'time')"
                    :rules="rules.time"
                  ></v-time-picker>
                </v-menu>
              </v-row>
              <p v-if="errorMsg.length > 0" class="red-error">{{ errorMsg }}</p>
              <v-card-actions>
                <!-- <v-btn @click="GoBack($router)" color="primary" v-t="'ml_114638'">
                  Back
                </v-btn> -->
                <v-btn
                  color="primary"
                  @click="GoHome($router)"
                  v-t="'ml_114649'"
                >
                  Home
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="onNext()"
                  :disabled="!valid"
                  v-t="'ml_114639'"
                >
                  Next
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <Modal :isVisible="showPrePostModal" @close="showPrePostModal = false">
      <template v-slot:header>
        <h3 v-t="'ml_151131'">Warning</h3>
      </template>
      <template v-slot:body>
        <p>{{modalMessage}}</p>
      </template>
      <template v-slot:footer>
        <div v-if="modalButtons.toLowerCase() === 'yes_no'">
          <v-btn variant="outlined" class="me-2" block @click="revertSelection" v-t="'ml_151133'">
            No
          </v-btn>
          <v-btn color="primary" block @click="showPrePostModal = false" v-t="'ml_151132'">
            Yes
          </v-btn>
        </div>
      </template>
    </Modal>
    <Modal :isVisible="showA_DT_Modal" @close="showA_DT_Modal = false">
      <template v-slot:header>
        <h3 v-t="'ml_151131'">Warning</h3>
      </template>
      <template v-slot:body>
        <p>{{modalA_DT_Message}}</p>
      </template>
      <template v-slot:footer>
        <v-btn variant="outlined" class="me-2" block @click="revertArrivalDTSelection" v-t="'ml_151133'">
            No
          </v-btn>
          <v-btn color="primary" block @click="showA_DT_Modal = false" v-t="'ml_151132'">
            Yes
          </v-btn>
      </template>
    </Modal>
    <Modal :isVisible="showNotificationModal" @close="showNotificationModal = false">
      <template v-slot:body>
        <p>{{modalNotificationMessage}}</p>
      </template>
    </Modal>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import nextTick from 'vue'
import {
  CloneObj,
  ConsoleLog,
  IsDebug,
  Timeout,
  validateOdometer,
  Text,
  GetDatePart,
  GetTimePart,
  IsNullOrEmpty,
  CountArrayDuplicate,
} from "@/utility";
import * as consts from "@/models/consts";
import {
  QuestionSectionType,
  PageName,
  TripType,
  SapVehcileCategory,
} from "@/models/enums";
import { StoreAction, StoreGetter, StoreMutation } from "@/store/store-mapping";
import { mapGetters } from "vuex";
import QrScaner from "@/components/QrScaner.vue";
import FormInput from "@/components/FormInput.vue";
import { ISelectItem, SelectItem } from "@/models/select-item";
import FormRadioGroup from "@/components/FormRadioGroup.vue";
import FormSelect from "@/components/FormSelect.vue";
import FormAutocomplete from "@/components/FormAutocomplete.vue";
import config from "@/config/config.json";

import {
  GoHome,
  GoQuestionTractor,
  GoTripInfo,
  GoCondition,
  GoOverview,
  GoBack,
  GetTranslationRequired,
  IsLocalMode,
  Confirm,
  Alert,
} from "@/utility/ui-utility";
import { IPlant, Plant } from "@/models/plant";
import { NotificationFilter } from "@/models/maitain-notification";
import { ValidateEvcr } from "@/services/data-process/basic-process";
import { EvcrHasQuestion } from "@/services/data-process/questions-process";

import {
  STR_TRIP_ID_MASK_LB,
  STR_TRIP_ID_MASK_PG,
  STR_TRIP_ID_PLACE_HOLDER_LB,
  STR_TRIP_ID_PLACE_HOLDER_PG,
} from "@/models/consts";
import { IVehicle, Vehicle } from "@/models/vehicle";
import { CreateTripInfo } from "@/models/trip-info";
import Modal from '@/components/Modal.vue';
export default Vue.extend({
  components: {
    QrScaner,
    FormInput,
    FormRadioGroup,
    FormSelect,
    FormAutocomplete,
    Modal
  },
  data() {
    return {
      customerLocation: {
        longitude: 0,
        latitude: 0,
      },
      locationMsg: "",
      currentScanType: QuestionSectionType.Tractor,
      menuDate: false,
      isTractorThirdParty:{type: Boolean, default: false},
      tractor3rdPartyVehType:"",
      isChassisThirdParty:{type: Boolean, default: false},
      chassis3rdPartyVehType:"",
      isVesselThirdParty:{type: Boolean, default: false},
      vessel3rdPartyVehType:"",
      menuTime: false,
      errorMsg: "",
      valid: false,
      rules: {
        tripType: [(v: string) => !!v || GetTranslationRequired()],
        tripId: [
          (v: string) => !!v || GetTranslationRequired(),
          (v: string) =>
            (v && v.length > 5 && v.length < 12) ||
            Text("ml_114680", "Field is invalid"),
        ],
        lbsTripIdRule: [] as any[],
        pgTripIdRule: [] as any[],
        pre: [(v: string) => !!v || GetTranslationRequired()],
        plant: [
          (v: any) => !!v || GetTranslationRequired(),
          (v: any) =>
            (this as any).validatePlant() ||
            Text("ml_114684", "Please select Plant"),
        ],
        customPlant: [
          
          (v: any) => (this as any).onlyChinaRequireCustomPlant() || GetTranslationRequired(),
          (v: any) =>
            (this as any).validateCustomPlant() ||
            Text("ml_114684", "Please select Plant"),
        ],        
        driver1: [(v: string) => !!v || GetTranslationRequired()],
        tractor: [(v: string) => !!v || GetTranslationRequired()],
        chassis: [(v: string) => !!v || GetTranslationRequired()],
        vessel: [(v: string) => !!v || GetTranslationRequired()],
        vehicleType: [(v: string) => !!v || GetTranslationRequired()],
        chassisVehicleType: [(v: string) => !!v || GetTranslationRequired()],
        vesselVehicleType: [(v: string) => !!v || GetTranslationRequired()],
        odometer: [
          (v: string) => !!v || GetTranslationRequired(),
          (v: any) =>
            (this as any).validateOdometerform(v) ||
            Text("ml_114683", "The Odometer is invalid, please enter again."),
        ],
        date: [(v: string) => !!v || GetTranslationRequired()],
        time: [(v: string) => !!v || GetTranslationRequired()],
      },
      QuestionSectionType,
      SapVehcileCategory,
      STR_TRIP_ID_MASK_LB,
      STR_TRIP_ID_MASK_PG,
      STR_TRIP_ID_PLACE_HOLDER_LB,
      STR_TRIP_ID_PLACE_HOLDER_PG,
      numberType: "number",
      showPrePostModal: false,
      showA_DT_Modal: false,
      isInitializing: true,
      modalMessage: '',
      modalButtons: '',
      modalA_DT_Message: '',
      modalA_DT_Buttons: '',
      showNotificationModal: false,
      modalNotificationMessage: ''
    };
  },
  computed: {
    ...mapGetters({
      tripInfo: StoreGetter.emTripInfo,
      nearbyPlant: StoreGetter.emNearbyPlant,
      vehicles: StoreGetter.emVehicles,
      notifications: StoreGetter.emMaintainNotifications,
      userId: StoreGetter.umUserId,
      userName: StoreGetter.umUserName,
      userCountryCode: StoreGetter.umUserCountryCode,
      isEdit: StoreGetter.emIsEdit,
      questionFilterChanged: StoreGetter.emQuestionFilterChanged,
      questions: StoreGetter.emQuestions,
      lastTripInfo: StoreGetter.lemLastEvcrTripInfoByDriver
    }),
    showLB(): boolean {
      ConsoleLog({ showLB: CloneObj(this.tripInfo.tripType) });
      this.refreshTripValidation();
      return this.tripInfo.tripType === TripType.LB ||
        IsNullOrEmpty(this.tripInfo.tripType)
        ? true
        : false;
    },
    showLbPlant(): boolean {
      let showLbPlant = false;
      // this.userCountryCode === 'CN' && this.tripInfo.prePost === 'PO' && this.tripInfo.tripType === 'LB'
      if(this.userCountryCode === 'CN'){
        if(this.tripInfo.tripType === 'LB'){
          if(this.tripInfo.prePost === 'PO'){
            showLbPlant = true;
            // this.tripInfo.customPlantId = this.tripInfo.lbsTripId.substring(0,4);
          }
        }
        
      }
      ConsoleLog({ showLbPlant: showLbPlant });
      return showLbPlant;
    },      
    showPgPlant(): boolean {
      let showPgPlant = false;
      if(this.userCountryCode === 'CN' && ( this.tripInfo.tripType === 'OT' || this.tripInfo.tripType === 'PG') ){
        showPgPlant = true;
        
      }
      ConsoleLog({ showPgPlant: showPgPlant });
      return showPgPlant;
    }, 
    showLbTripIdInput(): boolean {
      ConsoleLog({ showWhichTripIdInput: CloneObj(this.tripInfo.tripType) });
      this.refreshTripValidation();
      var result = false;
      if(this.tripInfo.tripType !== TripType.Other){
        if(this.tripInfo.tripType === TripType.LB || IsNullOrEmpty(this.tripInfo.tripType)){
          result=true;
        }
      }      
      return result;
    }, 
    showPgTripIdInput(): boolean {
      ConsoleLog({ showWhichTripIdInput: CloneObj(this.tripInfo.tripType) });
      this.refreshTripValidation();
      var result = false;
      if(this.tripInfo.tripType !== TripType.Other){
        if(this.tripInfo.tripType === TripType.PG){
          result=true;
        }
      }      
      return result;
    }, 
    showOtherTripIdInput(): boolean {
      ConsoleLog({ showOtherTripIdInput: CloneObj(this.tripInfo.tripType) });
      //this.refreshTripValidation();
      var result = false;
      if(this.tripInfo.tripType === TripType.Other){
        result=true;
      }
      return result;
    },              
    tripIdMask(): string {
      ConsoleLog({ tripIdMask: CloneObj(this.tripInfo) });
      return this.tripInfo.tripType === TripType.LB ||
        IsNullOrEmpty(this.tripInfo.tripType)
        ? STR_TRIP_ID_MASK_LB
        : consts.STR_TRIP_ID_MASK_PG;
    },
    tripIdPlaceHolder(): string {
      ConsoleLog({ tripIdPlaceHolder: CloneObj(this.tripInfo) });
      return this.tripInfo.tripType === TripType.LB ||
        IsNullOrEmpty(this.tripInfo.tripType)
        ? STR_TRIP_ID_PLACE_HOLDER_LB
        : consts.STR_TRIP_ID_PLACE_HOLDER_PG;
    },
    tripIdLabel(): string {
      return this.tripInfo.tripType === TripType.LB ||
        IsNullOrEmpty(this.tripInfo.tripType)
        ? Text("ml_114625", "LBShell Trip ID")
        : Text("ml_114902", "PG Trip ID");
    },
    ruleTripId(): any {
      ConsoleLog({ ruleTripId: this.tripInfo.tripType });
      return this.tripInfo.tripType === TripType.LB ||
        IsNullOrEmpty(this.tripInfo.tripType)
        ? [
            (v: string) => !!v || GetTranslationRequired(),
            (v: string) =>
              (v && v.length > 5 && v.length < 12) ||
              Text("ml_114680", "Field is invalid"),
          ]
        : [
            (v: string) => !!v || GetTranslationRequired(),
            (v: string) =>
              (v && v.length > 0 && v.length < 11) ||
              Text("ml_114680", "Field is invalid"),
          ];
    },
    validateLbsTripId(): boolean {
      const self = this as any;
      return this.tripInfo.tripType === TripType.LB ||
        IsNullOrEmpty(this.tripInfo.tripType)
        ? !IsNullOrEmpty(self.tripInfo.lbsTripId)
        : true;
    },
    validateLbsTripIdLength(): boolean {
      const self = this as any;
      return this.tripInfo.tripType === TripType.LB ||
        IsNullOrEmpty(this.tripInfo.tripType)
        ? self.tripInfo.lbsTripId.length > 5 &&
            self.tripInfo.lbsTripId.length < 12
        : true;
    },
    validatePgTripId(): boolean {
      const self = this as any;
      return self.tripInfo.tripType === TripType.PG
        ? !IsNullOrEmpty(self.tripInfo.pgTripId)
        : true;
    },
    validatePgTripIdLength(): boolean {
      const self = this as any;
      return self.tripInfo.tripType === TripType.PG
        ? self.tripInfo.pgTripId.length > 0 &&
            self.tripInfo.pgTripId.length < 11
        : true;
    },
    validatePlantIdFromLbsTripId(): boolean {
      const self = this as any;
      let validPlantId = false;
      const plantId = self.tripInfo.lbsTripId.substring(0,4);
      const plants = this.nearbyPlant as IPlant[];
      ConsoleLog({ 'this.userCountryCode in validatePlantIdFromLbsTripId': this.userCountryCode });
      if(this.userCountryCode === 'CN'){
        if(self.tripInfo.tripType === 'LB'){
          if(plants.filter((item: IPlant) => item.plantId === plantId).length > 0){
            validPlantId = true;
          }
        }
        else if(self.tripInfo.tripType === 'PG'){
          validPlantId = true;
        }
        else if(self.tripInfo.tripType === 'OT'){
          validPlantId = true;
        }        
      }
      else{
        validPlantId = true;
      }
      return validPlantId;
    },
    showDT(): string {
      const self = this as any;      
      let showDT = '';
      if(self.tripInfo.tripType === 'OT' || self.tripInfo.tripType === 'PG'){
        if(self.tripInfo.prePost === 'PR'){
          showDT = Text('ml_116552', 'Departure DT');
        }
        else{
          showDT = Text('ml_116553', 'Arrival DT');
        }
      }
      return showDT;
    }
  },
  methods: {
    revertSelection() {
      const self = this as any;
      self.tripInfo.prePost = self.tripInfo.prePost.toUpperCase() === "PR" ? "PO" : "PR";
      this.showPrePostModal = false;
      self.refreshTripValidation();
    },
    revertArrivalDTSelection() {
      this.tripInfo.customPlantId = this.tripInfo.lbsTripId.substring(0,4);
      this.showA_DT_Modal = false;
    },
    updateLbTripIdInfo(newValue: string) {
      // ConsoleLog({ updateTripIdInfo: newValue });
      const exists = this.tripInfo.customPlantId && this.tripInfo.customPlantId.length === 4;
      this.tripInfo.customPlantId =  exists && this.tripInfo.prePost === "PO" ? this.tripInfo.customPlantId : this.tripInfo.lbsTripId.substring(0,4);
      this.updateTripInfo(false, "tripId");
    },
    updateTripIdInfo(newValue: string) {
      // ConsoleLog({ updateTripIdInfo: newValue });
      // this.tripInfo.customPlantId = this.tripInfo.lbsTripId.substring(0,4);
      this.updateTripInfo(false, "tripId");
    },  
    refreshTripValidation(): void {
      ConsoleLog({ refreshTripValidation: "" });
      this.rules.lbsTripIdRule.length = 0;
      this.rules.pgTripIdRule.length = 0;
      this.rules.lbsTripIdRule = [
        (v: string) =>
          (this as any).validateLbsTripId || GetTranslationRequired(),
        (v: string) =>
          (this as any).validateLbsTripIdLength ||
          Text("ml_114680", "Field is invalid"),
        (v: string) =>
          (this as any).validatePlantIdFromLbsTripId || 
          Text("ml_116554", "Plant is invalid"),
      ];
      this.rules.pgTripIdRule = [
        (v: string) =>
          (this as any).validatePgTripId || GetTranslationRequired(),
        (v: string) =>
          (this as any).validatePgTripIdLength ||
          Text("ml_114680", "Field is invalid"),
      ];
    },
    setDefaultPlant():void{
      switch(this.userCountryCode){
        case 'CN':
          this.tripInfo.plantId="7561";
          this.tripInfo.plantName="昆山充装厂";
          break;
        case 'MY':
          this.tripInfo.plantId="7048";
          this.tripInfo.plantName="SHAH ALAM TERMINAL";
          break;
        case 'SG':
          this.tripInfo.plantId="7074";
          this.tripInfo.plantName="SAKRA TERMINAL";
          break;
        case 'ID':
          this.tripInfo.plantId="7010";
          this.tripInfo.plantName="CIKARANG TERMINAL";
          break;
        default:
          this.tripInfo.plantId="9999";
          this.tripInfo.plantName="xxxx";
      }      
    },
    validatePlant() {
      // Test validate plant: the plant must be in nearbyPlant
      const plants = this.nearbyPlant as IPlant[];
      return (
        plants.filter((item: IPlant) => item.plantId === this.tripInfo.plantId)
          .length > 0
      );
    },
    onlyChinaRequireCustomPlant() {
      // Test validate plant: the plant must be in nearbyPlant
      let onlyChinaRequireCustomPlant = true;
      if(this.userCountryCode === 'CN'){
        if(this.tripInfo.customPlantId === ''){
          onlyChinaRequireCustomPlant = false ;
        }
        
      }
      ConsoleLog({ RequireCustomPlant: onlyChinaRequireCustomPlant });
      return onlyChinaRequireCustomPlant;
    },
    validateCustomPlant() {
      // Test validate plant: the plant must be in nearbyPlant
      let validCustomPlant = false;
      const customPlant =  this.tripInfo.customPlantId
      const plants = this.nearbyPlant as IPlant[];
      if(this.userCountryCode === 'CN'){
        if(plants.filter((item: IPlant) => item.plantId === customPlant).length > 0){
          validCustomPlant = true;
        }
      }
      else{
        validCustomPlant = true;
      }
      ConsoleLog({ validCustomPlant: validCustomPlant });
      return validCustomPlant;
    },    
    validateOdometerform(value: string) {
      const isGood = validateOdometer(value);
      // ConsoleLog({ validateOdometerform: isGood, value: value });
      return isGood;
    },
    GoBack,
    IsDebug,
    Text,
    IsLocalMode,
    GoHome,
    maxDate() {
      const currentDate = new Date();
      return GetDatePart(currentDate);
    },
    maxTime() {
      return GetTimePart(new Date());
    },
    clearLocationMsg() {
      this.locationMsg = "";
    },
    async getLocation() {
      this.errorMsg = "";
      const self = this as any;
      self.$store.commit(StoreMutation.StartLoading, null, { root: true });
      self.clearLocationMsg();
      self.$store.commit(StoreMutation.emClearNearByData);
      if (await navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            ConsoleLog([
              "customerLocation",
              position.coords.longitude,
              position.coords.latitude,
              position.coords,
            ]);
            self.customerLocation.latitude = position.coords.latitude; // 51.3634;
            self.customerLocation.longitude = position.coords.longitude; // -0.2530;//
            self.$store.commit(StoreMutation.EndLoading, null, { root: true });
            await self.$store.dispatch(StoreAction.emGetNearbyPlant, {
              longitude: self.customerLocation.longitude,
              latitude: self.customerLocation.latitude,
            });
            this.errorMsg = this.$store.state.evcrModule.errorMsg;
            // { latitude: 51.3634,longitude: -0.253, }
            // { latitude: self.customerLocation.latitude, longitude: self.customerLocation.longitude });
          },
          (error) => {
            self.$store.commit(StoreMutation.EndLoading, null, { root: true });
            ConsoleLog({ "error.code for geolocation": error.code });
            if (error.code === 1) {
              self.locationMsg =
                error.message +
                `. ${Text("ml_114681", "Please enable location services")}`;
            } else if (error.code === 2) {
              self.locationMsg =
                error.message +
                `. ${Text("ml_114681", "Please enable location services")}`;
            } else {
              self.locationMsg =
                error.message + `. ${Text("ml_114682", "Please try again.")}`;
            }
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        self.locationMsg = "Internet Browser not supported";
      }
    },
    async initialize() {
      const self = this as any;
      await self.$store.dispatch(StoreAction.emInitialData, {
        longitude: this.customerLocation.longitude,
        latitude: this.customerLocation.latitude,
      });
      if (this.showStateMsg("TripInformation.initialize.emInitialData")) {
        return;
      }
      await self.$store.dispatch(StoreAction.emInitialTripData, {
        countryCode: this.userCountryCode,
      });
      if (this.showStateMsg("TripInformation.initialize.emInitialTripData")) {
        return;
      }
      const trip = CreateTripInfo();
      trip.driver1 = this.tripInfo.driver1;
      await self.$store.dispatch(StoreAction.lvmGetLastEvcrByDriver, {
        trip: trip
      }); 
    },
    showStateMsg(logName: string = "") {
      let hasError = false;
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.errorMsg = this.$store.state.evcrModule.errorMsg;
        if (!IsNullOrEmpty(logName)) {
          ConsoleLog({ logName: this.errorMsg });
        }
        hasError = true;
      }
      return hasError;
    },

    updateTripType(newValue: string): void {
      ConsoleLog({
        "FormRadioGroup.userChange": newValue
      });
      this.$store.commit(StoreMutation.emClearTripId);
      //how to call the change event for TripId?
      // const self = this as any;
      // if (self.$refs.lbsTripId) {
      //   ConsoleLog(' self.$refs.lbsTripId.onChanging("")');
      //   self.$refs.lbsTripId.onChanging("");
      // }
      ConsoleLog({
        "this.tripInfo.lbsTripId": this.tripInfo.lbsTripId,
        "this.tripInfo.pgTripId": this.tripInfo.pgTripId,
        "this.tripInfo.prePost": this.tripInfo.prePost,
        "this.tripInfo.customPlantId": this.tripInfo.customPlantId
      });
      this.tripInfo.lbsTripId = "";
      this.tripInfo.pgTripId = "";
      this.tripInfo.prePost = ""; //"PR"; // "PR";
      this.tripInfo.customPlantId = "";
      ConsoleLog({
        "this.tripInfo.lbsTripId": this.tripInfo.lbsTripId,
        "this.tripInfo.pgTripId": this.tripInfo.pgTripId,
        "this.tripInfo.prePost": this.tripInfo.prePost,
        "this.tripInfo.customPlantId": this.tripInfo.customPlantId
      });
      this.updateTripInfo(false, "updateTripType");
      ConsoleLog({
        "FormRadioGroup.userChange": newValue,
        "this.tripInfo.lbsTripId": this.tripInfo.lbsTripId,
      });
      //this.$forceUpdate();
      //await nextTick()
      //nextTick()
      Vue.nextTick(() => {}) // syntax
    },

    handleTractorChange(e: string) {
      ConsoleLog({ handleTractorChange: e });
      this.tripInfo.tractor = e;
      this.updateTripInfo(false, "handleTractorChange");
    },
    handleChassisChange(e: string) {
      ConsoleLog({ handleChassisChange: e });
      this.tripInfo.chassis = e;
      this.updateTripInfo(false, "handleChassisChange");
    },
    handleVesselChange(e: string) {
      ConsoleLog({ handleVesselChange: e });
      this.tripInfo.vessel = e;
      this.updateTripInfo(false, "handleVesselChange");
    },
    // scanCode(scanType: QuestionSection): void {
    //   this.currentScanType = scanType;
    //   if (scanType == QuestionSection.Tractor) {
    //   }
    //   this.retry();
    //   this.onInit;
    // },

    async onNext(): Promise<void> {
      // await Timeout(1000);
      this.errorMsg = "";
      var isOk = false;
      const self = this as any;
      if (!self.$refs.form.validate()) {
        return;
      }

      this.tripInfo.language=Vue.prototype.$lanCode;
      //tripInfo.country=tractorFound.country;
      this.tripInfo.country=this.userCountryCode;

      if(this.tripInfo.country==='CN'){
        if(this.tripInfo.isTractorThirdParty){
          this.tripInfo.tractor = this.tripInfo.tractor.replace(/\s+/g, '').toUpperCase();
        }
        if(this.tripInfo.isChassisThirdParty){
          this.tripInfo.chassis = this.tripInfo.chassis.replace(/\s+/g, '').toUpperCase();
        }
        if(this.tripInfo.isVesselThirdParty){
          this.tripInfo.vessel = this.tripInfo.vessel.replace(/\s+/g, '').toUpperCase();
        }
      }

      //if(this.validatePlant()){
      //  this.tripInfo.customPlantId = this.tripInfo.lbsTripId.substring(0,4);
      //}

      //if(this.tripInfo.isTractorThirdParty && this.tripInfo.isChassisThirdParty && this.tripInfo.isVesselThirdParty){
      //    this.tripInfo.plantId="9999";
      //    this.tripInfo.plantName="xxxx";
      //}

      if(this.tripInfo.isTractorThirdParty){
        if(this.tripInfo.isChassisThirdParty){
          if(this.tripInfo.isVesselThirdParty){
            //this.tripInfo.plantId="9999";
            //this.tripInfo.plantName="xxxx";
            this.setDefaultPlant();
          }
          else{
            if(this.tripInfo.vessel){
              const vesselFound = this.vehicles(SapVehcileCategory.Vessel).filter(item => item.vesselId === this.tripInfo.vessel)[0] as IVehicle;
              if(vesselFound){
                this.tripInfo.plantId=vesselFound.plant;
                this.tripInfo.plantName=vesselFound.name1;
              }
            }
            else{
              //this.tripInfo.plantId="9999";
              //this.tripInfo.plantName="xxxx"; 
              this.setDefaultPlant();           
            }              
          }
        }
        else{
          if(this.tripInfo.chassis){
            const chassisFound = this.vehicles(SapVehcileCategory.Chassis).filter(item => item.vehicleId === this.tripInfo.chassis)[0] as IVehicle;
            if(chassisFound){
              this.tripInfo.plantId=chassisFound.plant;
              this.tripInfo.plantName=chassisFound.name1;
            }
          }
          else{
            //this.tripInfo.plantId="9999";
            //this.tripInfo.plantName="xxxx"; 
            this.setDefaultPlant();           
          }  
        }
      }
      else{
        if(this.tripInfo.tractor){
          const tractorFound = this.vehicles(SapVehcileCategory.Tractor).filter(item => item.vehicleId === this.tripInfo.tractor)[0] as IVehicle;
          if(tractorFound){
            this.tripInfo.plantId=tractorFound.plant;
            this.tripInfo.plantName=tractorFound.name1;
            //this.tripInfo.language=Vue.prototype.$lanCode;
            //tripInfo.country=tractorFound.country;
            //this.tripInfo.country=this.userCountryCode;
            
          }
        } 
      }

      this.updateTripInfo(true, "onNext");
      if (this.questionFilterChanged && EvcrHasQuestion(this.questions)) {
        if (
          !Confirm(
            Text(
              "ml_114758",
              "The eVCR check list would be refreshed due to vehicle changes, all your answers would be cleared up. Do you want to continue?"
            )
          )
        ) {
          return;
        }
      }
      // self.$store.commit(StoreMutation.emUpdateTripInfo);
      //back-end validation
      //eVCR is duplicated, please try another one.
      //The APEXID of Driver 1 does not exist, please enter again.
      //Tractor/Rigid/Truck, Chassis and Pressure Vessel are already existing.
      //isOk = await self.$store.dispatch(StoreAction.emValidateNewEvcr);
      //this.showStateMsg();
      //if (!isOk) {
      //  return;
      //}

      let isVehicleCategoryduplicate = false;
      isVehicleCategoryduplicate = this.checkWhetherVehicleCategoryDuplicate();
      if(isVehicleCategoryduplicate){
        this.errorMsg = Text('ml_115266', "Error: The vehicle combination is invalid, please confirm your entry. If it is still invalid, please contact your supervisor.");
        return;
      }      

      let isTripTimeFuture = false;
      isTripTimeFuture = this.checkWhetherTimeIsFuture();
      if(isTripTimeFuture){
        this.errorMsg = Text('ml_143012', "Do not enter a future date or time");
        return;
      }

      //validate maintenance notification
      let hasNotification = false;
      //await self.$store.dispatch(StoreAction.emGetMaintainNotification, {
      //  filter: new NotificationFilter(
      //    this.tripInfo.tractor,
      //    this.tripInfo.thirdParty.toString(),
      //    this.tripInfo.chassis,
      //    this.tripInfo.vessel,
      //    ""
      //  ),
      //});

      //await self.$store.dispatch(StoreAction.emGetMaintainNotification, {
      //  filter: new NotificationFilter(
      //    this.tripInfo.isTractorThirdParty ? '' : this.tripInfo.tractor,
      //    this.tripInfo.thirdParty.toString(),
      //    this.tripInfo.isChassisThirdParty ? '' : this.tripInfo.chassis,
      //    this.tripInfo.isVesselThirdParty ? '' : this.tripInfo.vessel,
      //    ""
      //  ),
      //});

      //if (this.showStateMsg("emGetMaintainNotification")) {
      //  return;
      //}
      //// hasNotification = await ValidateNotification(this.$store.state.evcrModule);
      //hasNotification = this.notifications.length > 0;


      if(this.containNonThreePartyVehicle()){
        await self.$store.dispatch(StoreAction.emGetMaintainNotification, {
          filter: new NotificationFilter(
            this.tripInfo.isTractorThirdParty ? '' : this.tripInfo.tractor,
            this.tripInfo.thirdParty.toString(),
            this.tripInfo.isChassisThirdParty ? '' : this.tripInfo.chassis,
            this.tripInfo.isVesselThirdParty ? '' : this.tripInfo.vessel,
            ""
          ),
        });
        if (this.showStateMsg("emGetMaintainNotification")) {
          return;
        }
        hasNotification = this.notifications.length > 0;
      }



      if (hasNotification) {
        GoCondition(self.$router);
      } else {
        GoOverview(self.$router);
      }
    },
    containNonThreePartyVehicle(): boolean {
      let containNonThreePartyVehicle = false;
      if(
        (!this.tripInfo.isTractorThirdParty && !IsNullOrEmpty(this.tripInfo.tractor))
        || (!this.tripInfo.isChassisThirdParty && !IsNullOrEmpty(this.tripInfo.chassis))
        || (!this.tripInfo.isVesselThirdParty && !IsNullOrEmpty(this.tripInfo.vessel))
        ){
         containNonThreePartyVehicle = true;
      }
      else{
        
      }
      return containNonThreePartyVehicle;
    },
    checkWhetherTimeIsFuture(): boolean {
      let whetherTimeIsFuture = false;
      const dateArray = this.tripInfo.date.split('-');
      ConsoleLog({ 'trip information page time': this.tripInfo.time });  
      const timeArray = this.tripInfo.time.split(':');
      const timeCurrent = new Date();
      //const timeFromPage = new Date();
      const timeFromPage = new Date(this.tripInfo.date);
      //timeFromPage.setHours(timeArray[0], timeArray[0], 00);
      timeFromPage.setHours(timeArray[0], timeArray[1]);
      ConsoleLog({ 'timeCurrent': timeCurrent });  
      ConsoleLog({ 'timeFromPage': timeFromPage });  
      if( timeFromPage > timeCurrent ){
        ConsoleLog('trip date time is future');
        whetherTimeIsFuture = true;
      }
      else{
        ConsoleLog('trip date time is not future');
      }
      //if( timeCurrent.getFullYear() = timeFromPage.getFullYear() && timeCurrent.getMonth() = timeFromPage.getMonth() && timeCurrent.getDate() = timeFromPage.getDate() ){
      //  ConsoleLog('trip date is current date');
      //  if( timeFromPage.getHours() > timeCurrent.getHours() ){
      //    ConsoleLog('trip hour is future');
      //    whetherTimeIsFuture = true;
      //  }
      //  else{
      //    ConsoleLog('trip hour is not future');
      //    if( timeFromPage.getHours() = timeCurrent.getHours() ){
      //      ConsoleLog('trip hour is current hour');
      //      //ConsoleLog('trip minute is future');
      //      if(){}
      //      whetherTimeIsFuture = true;
      //    }
      //    else{
      //      //ConsoleLog('trip minute is not future');
      //      ConsoleLog('trip hour is not current hour');
      //    }
      //  }
      //}
      //else{
      //  ConsoleLog('trip date is not current date');
      //}
      return whetherTimeIsFuture;
    },  
    checkWhetherVehicleCategoryDuplicate(): boolean {
      let whetherVehicleCategoryDuplicate = false;
      let vehicleCategoryArray : string[]  = [];

      if(this.tripInfo.isVesselThirdParty){
        vehicleCategoryArray.push(`Vessel`)
      }
      else{
        if(this.tripInfo.vessel){
          const vesselFound = this.vehicles(SapVehcileCategory.Vessel).filter(item => item.vesselId === this.tripInfo.vessel)[0] as IVehicle;
          if(vesselFound){
            if(vesselFound.category){
              vehicleCategoryArray.push(vesselFound.category)
            }
            if(vesselFound.category2){
              vehicleCategoryArray.push(vesselFound.category2)
            }
          }
        }
      }
        
      
      if(this.tripInfo.isChassisThirdParty){
        vehicleCategoryArray.push(`Chassis`)
      }
      else{
        if(this.tripInfo.chassis){
          const chassisFound = this.vehicles(SapVehcileCategory.Chassis).filter(item => item.vehicleId === this.tripInfo.chassis)[0] as IVehicle;
          if(chassisFound){
            if(chassisFound.category){
              vehicleCategoryArray.push(chassisFound.category)
            }
            if(chassisFound.category2){
              vehicleCategoryArray.push(chassisFound.category2)
            }
          }
        }
      }

      if(this.tripInfo.isTractorThirdParty){
        vehicleCategoryArray.push(`Tractor`)
      }
      else{
        if(this.tripInfo.tractor){
          const tractorFound = this.vehicles(SapVehcileCategory.Tractor).filter(item => item.vehicleId === this.tripInfo.tractor)[0] as IVehicle;
          if(tractorFound){
            if(tractorFound.category){
              vehicleCategoryArray.push(tractorFound.category)
            }
            if(tractorFound.category2){
              vehicleCategoryArray.push(tractorFound.category2)
            }
          }  
        }
      }
       
      
      ConsoleLog({ vehicleCategoryArray: CloneObj(vehicleCategoryArray)});            
      if(CountArrayDuplicate(vehicleCategoryArray) !== 0){
        //ConsoleLog({ vehicleCategoryArray: CloneObj(vehicleCategoryArray)});
        whetherVehicleCategoryDuplicate = true;
      }
      return whetherVehicleCategoryDuplicate;
    },
    updateTripInfoTractor() {
      if(this.tripInfo.isTractorThirdParty){
        this.tripInfo.tractor = this.tripInfo.tractor.replace(/\s+/g, '').toUpperCase();
      }
      this.updateTripInfo(false, "updateTripInfoTractor");
    },   
    updateTripInfoChassis() {
      if(this.tripInfo.isChassisThirdParty){
        this.tripInfo.chassis = this.tripInfo.chassis.replace(/\s+/g, '').toUpperCase();
      }
      this.updateTripInfo(false, "updateTripInfoChassis");
    },   
    updateTripInfoVessel() {
      if(this.tripInfo.isVesselThirdParty){
        this.tripInfo.vessel = this.tripInfo.vessel.replace(/\s+/g, '').toUpperCase();
      }
      this.updateTripInfo(false, "updateTripInfoVessel");
    },            
    updateTripInfo(updatedDriver: boolean = false, type: string) {
      ConsoleLog({ updateTripInfo: CloneObj(this.tripInfo), type: type });
      const self = this as any;
      self.$store.commit(StoreMutation.emUpdateTripInfo, {
        tipInfo: this.tripInfo,
      });
      if (updatedDriver) {
        self.$store.commit(StoreMutation.emUpdateIsEdit, { isEdit: true });
      }
    },
    onUserVehicleFilter(
      item: Vehicle,
      queryText: string,
      itemText: string,
      category: string
    ): boolean {
      const searchText = queryText.toLowerCase();
      let found = false;
      found =
        //item.category.toLowerCase() === category.toLowerCase() &&
        item.vehicleId.toLowerCase().indexOf(searchText) > -1;
      // console.log("onUserFilter", item, queryText, itemText, found);
      return found;
    },
    onUserVesselFilter(
      item: Vehicle,
      queryText: string,
      itemText: string,
      category: string
    ): boolean {
      const searchText = queryText.toLowerCase();
      let found = false;
      found =
        //item.category.toLowerCase() === category.toLowerCase() &&
        item.vesselId.toLowerCase().indexOf(searchText) > -1;
      // console.log("onUserFilter", item, queryText, itemText, found);
      return found;
    },    
    onTractorThirdPartyStatusChange(newValue: string, tripInfo: any): void {
      ConsoleLog(["onTractorThirdPartyStatusChange", newValue, CloneObj(tripInfo)]);
      tripInfo.tractor = '';
      this.tripInfo.plantId='';
      this.tripInfo.plantName='';
      if(!newValue){
        tripInfo.tractor3rdPartyVehType = '';
      }
      this.updateTripInfo(false, "onTractorThirdPartyStatusChange");
    },    
    onChassisThirdPartyStatusChange(newValue: string, tripInfo: any): void {
      ConsoleLog(["onChassisThirdPartyStatusChange", newValue, CloneObj(tripInfo)]);
      tripInfo.chassis = '';
      this.tripInfo.plantId='';
      this.tripInfo.plantName='';      
      if(!newValue){
        tripInfo.chassis3rdPartyVehType = '';
      }
      this.updateTripInfo(false, "onChassisThirdPartyStatusChange");
    },
    onVesselThirdPartyStatusChange(newValue: string, tripInfo: any): void {
      ConsoleLog(["onVesselThirdPartyStatusChange", newValue, CloneObj(tripInfo)]);
      tripInfo.vessel = '';
      this.tripInfo.plantId='';
      this.tripInfo.plantName='';      
      if(!newValue){
        tripInfo.vessel3rdPartyVehType = '';
      }
      this.updateTripInfo(false, "onVesselThirdPartyStatusChange");
    },
    onVehicleChange(newValue: string, tripInfo: any, category: string): void {
      // ConsoleLog(["onPlantChange", newValue, CloneObj(tripInfo)]);
      if (category === SapVehcileCategory.Tractor) {
        tripInfo.tractor = newValue; 
      } else if (category === SapVehcileCategory.Chassis) {
        tripInfo.chassis = newValue;
      } else if (category === SapVehcileCategory.Vessel) {
        tripInfo.vessel = newValue;
      // if (newValue === "Tube Skid") {
      //     //•	if it is a “Tube Skid”, warn the user not to use the app outside the tractor, or they can use it in a safe environment
      //     Alert(
      //       Text(
      //         "ml_114942",
      //         "Please do not to use the app outside the tractor, or you can use it in a safe environment."
      //       )
      //     );
      //   }  
      }
      this.updateTripInfo(false, "onVehicleChange");
    },
    onUserFilter(item: Plant, queryText: string, itemText: string): boolean {
      const searchText = queryText.toLowerCase();
      let found = false;
      found =
        item.plantId.toLowerCase().indexOf(searchText) > -1 ||
        item.name1.toLowerCase().indexOf(searchText) > -1 ||
        // item.name2.toLowerCase().indexOf(searchText) > -1 ||
        item.city.toLowerCase().indexOf(searchText) > -1 ||
        item.enname.toLowerCase().indexOf(searchText) > -1 ||
        // item.enname2.toLowerCase().indexOf(searchText) > -1 ||
        item.encity.toLowerCase().indexOf(searchText) > -1;
      // console.log("onUserFilter", item, queryText, itemText, found);
      return found;
    },
    onPlantChange(newValue: string, tripInfo: any): void {
      tripInfo.customPlantId = newValue;
      this.updateTripInfo(false, "onPlantChange");
    },

    getPlantItems(plants: IPlant[]) {
      const items: ISelectItem[] = [];
      plants.forEach((plant) => {
        items.push(
          new SelectItem(`${plant.plantId}-${plant.name1}`, plant.plantId)
        );
      });
      return items;
    },

    getTractorCategoryItems() {
      const items: ISelectItem[] = [];
      items.push(
          new SelectItem(Text("ml_116402", "Tractor"), `TRACTOR`)
          ,new SelectItem(Text("ml_116403", "Rigid PGG Truck"), `RIGID PGG TRUCK`)
          ,new SelectItem(Text("ml_116404", "Rigid PGG Knuckle Crane Truck"), `RIGID PGG KNUCKLE CRANE TRUCK`)
          ,new SelectItem(Text("ml_116405", "Rigid Tanker"), `RIGID TANKER`)
        );
      //ConsoleLog({ getTractorCategoryItems: CloneObj(items) });
      return items;
    },
    getChassisCategoryItems() {
      const items: ISelectItem[] = [];
      items.push(
          new SelectItem(Text("ml_116406", "Chassis Trailer"), `CHASSIS TRAILER`)
          ,new SelectItem(Text("ml_116407", "PGG Chassis"), `PGG CHASSIS`)
        );
      //ConsoleLog({ getChassisCategoryItems: CloneObj(items) });
      return items;
    },
    getVesselCategoryItems() {
      const items: ISelectItem[] = [];
      items.push(
          new SelectItem(Text("ml_116408", "LB Semi/MB Semi/ISO Tanker"), `LB~!@MB~!@ISO TANKER`)
          ,new SelectItem(Text("ml_116409", "Tube Skid"), `TUBE SKID`)
        );
      //ConsoleLog({ getVesselCategoryItems: CloneObj(items) });
      return items;
    },
    showVesselAutoCompleteAsLabel(): boolean {
      const self = this as any;
      let showAsLabel = false;
      if(self.tripInfo.chassis !== ""){
        const chassisFound = self.vehicles(SapVehcileCategory.Chassis).filter(item => item.vehicleId === self.tripInfo.chassis)[0] as IVehicle;
        if(chassisFound){
          if(chassisFound.category2 === SapVehcileCategory.Vessel || chassisFound.category === SapVehcileCategory.Vessel){
            self.tripInfo.vessel="";
            showAsLabel = true;
          }
        }
      }
      return showAsLabel;
    },
    showChassisAutoCompleteAsLabel(): boolean {
      const self = this as any;
      let showAsLabel = false;
      if(self.tripInfo.vessel !== ""){
        const vesselFound = self.vehicles(SapVehcileCategory.Vessel).filter(item => item.vesselId === self.tripInfo.vessel)[0] as IVehicle;
        if(vesselFound){
          if(vesselFound.category2 === SapVehcileCategory.Chassis  || vesselFound.category === SapVehcileCategory.Chassis){
            self.tripInfo.chassis="";
            showAsLabel = true;
          }
        }
      }
      return showAsLabel;
    },
    async AlertTripPrePostChange() {
      const self = this as any;
      if (!self.isInitializing && self.lastTripInfo) {
        self.modalButtons = 'yes_no';
        if(self.lastTripInfo.PrePost.toUpperCase() === self.tripInfo.prePost.toUpperCase()) {
            const currentTrip = self.tripInfo.prePost.toUpperCase() === 'PR' ? Text('ml_114687', 'Pre') : Text('ml_114688', 'Post');
            let modalMsg = Text('ml_151118', 'The last eVCR record you submitted is also a {{ currentPrePost }} trip, Do you still want to submit an eVCR record for {{ currentPrePost }} trip?')
            self.modalMessage = modalMsg.replace('{{ currentPrePost }}', currentTrip).replace('{{ currentPrePost }}', currentTrip);
            self.showPrePostModal = true;
        }
      }
    },
    async LoadTripInfo() {
      const self = this as any;
      if (!self.isInitializing && self.lastTripInfo) {
        if (self.tripInfo.prePost.toUpperCase() === 'PO') {
          let lastTrip = CreateTripInfo();
          lastTrip.tripType = self.lastTripInfo.TripType;
          lastTrip.lbsTripId = self.lastTripInfo.LBShellTrip;
          lastTrip.tractor = self.lastTripInfo.TractorLicense;
          lastTrip.chassis = self.lastTripInfo.ChassisLicense;
          lastTrip.vessel = self.lastTripInfo.LBShellID;
          lastTrip.isTractorThirdParty = self.lastTripInfo.Tractor3rdParty === "X";
          lastTrip.isChassisThirdParty = self.lastTripInfo.Chassis3rdParty === "X";
          lastTrip.isVesselThirdParty = self.lastTripInfo.Vessel3rdParty === "X";
          lastTrip.tractor3rdPartyVehType = self.lastTripInfo.Tractor3rdPartyVehType;
          lastTrip.chassis3rdPartyVehType = self.lastTripInfo.Chassis3rdPartyVehType;
          lastTrip.vessel3rdPartyVehType = self.lastTripInfo.Vessel3rdPartyVehType;
          lastTrip.driver1 = this.userId;
          lastTrip.driver1Name = this.userName;
          lastTrip.prePost = self.tripInfo.prePost;
          lastTrip.customPlantId = self.lastTripInfo.LBShellTrip.substring(0,4);
          self.$store.commit(StoreMutation.emUpdateTripInfo, {
            tipInfo: lastTrip,
          });
          self.modalNotificationMessage = Text('ml_151171', 'The trip info has been automatically populated based on the pre trip eVCR, please verify it.');;
          self.showNotificationModal = true;
          await Timeout(5000);
          self.showNotificationModal = false;
        }
      }
    },
    async AlertArrivalDTChange() {
      const self = this as any;
      if (!self.isInitializing) {
        this.modalA_DT_Buttons = 'yes_no';
        this.modalA_DT_Message = Text('ml_151141', 'Are you sure to change the default DT?');
        this.showA_DT_Modal = true;
      }
    }
  },
  watch: {
    "tripInfo.prePost": async function (newVal, oldVal) {
      await this.AlertTripPrePostChange();
      if (newVal.toUpperCase() === 'PO' && !this.showPrePostModal) {
        await this.LoadTripInfo();
      }
    },
    "showPrePostModal": async function(newVal, oldVal) {
      if (this.tripInfo.prePost.toUpperCase() === 'PO' && !newVal) {
        await this.LoadTripInfo();
      }
    },
    "tripInfo.customPlantId": async function(newVal, oldVal) {
      if (this.tripInfo.tripType === TripType.LB && newVal.length === 4) {
        const orgValue = this.tripInfo.lbsTripId.substring(0,4);
        if (oldVal.length === 4 && orgValue != newVal) {
          await this.AlertArrivalDTChange();
        }
      }
    }
  },
  async created() {
    const self = this as any;
    self.clearLocationMsg();
    // await self.getLocation();
    await this.initialize();
    // this.locationMsg = this.$store.state.evcrModule.errorMsg;
  },
  async beforeMount() {
    // await this.initialize();
    // ConsoleLog({ "TripInformation.beforeMount": CloneObj(this.tripInfo) });
    // if (!this.isEdit && !IsLocalMode()) {
    // if (!this.isEdit) {
    this.tripInfo.driver1 = this.userId;
    this.tripInfo.driver1Name = this.userName;
  },
  mounted(): void {
    const self = this as any;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (this.isInitializing) {
      self.isInitializing = false;
    }
  }
});
</script>

<style></style>
