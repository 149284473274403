import { QuestionSectionType, QuestionType, RegularAnswer, SapQuestionType } from "@/models/enums";
import * as consts from "@/models/consts";
import { CreateQuestionTractor, CreateQuestionChassis, CreateQuestionVessel, IQuestionSection, IQuestionFilter, QuestionSection, CreateQuestionAll } from "@/models/question-section";
import { CreateEmptyFile, IQuestion, Question } from "@/models/question-section/question";
import { ISubSection, SubSection } from "@/models/question-section/sub-section";
import { CloneObj, ConsoleLog, DeepClone, IsNullOrEmpty } from "@/utility";
import { ServiceCallResponse } from "@/models/service";
import { OrderedBySequnceId, OrderedBySubSectionId } from "@/utility/sortHelper";
import { GetTranslationAdditionalQues, QuestionsPicName, QuestionsThumbnailName } from "@/utility/ui-utility";
import { ConvertQueTypeToFrontEnd, IsBooleanQuestion } from "./basic-process";

import config from "@/config/config.json";
import { IAttachment } from "@/models/question-section/attachment";

export function GenerateQuestions(data: ServiceCallResponse, filter: IQuestionFilter) {
    ConsoleLog(['GenerateQuestions', CloneObj(data), CloneObj(filter)]);
    //the first part is regular questions for all part
    // the second part is addional questions for all part.
    if (!data || !data.result || data.result.length === 0) {
        return { questions: {} as QuestionSection };
    }
    const tractorSection = GenerateQuestionSection(data.result, QuestionSectionType.Tractor, filter)
    return { questions: tractorSection }
}


export const GenerateQuestionSection = (sapQuestios: any[], sectionType: QuestionSectionType, filter: IQuestionFilter) => {
    ConsoleLog(['GenerateQuestionSection', CloneObj(sapQuestios), sectionType]);
    const additionalQuestions = sapQuestios.filter(item => item.QuestionType === consts.SAP_QUESTION_TYPE_ADDTIONAL);//.sort(OrderedBySequnceId);
    const regularQuestions = sapQuestios.filter(item => item.QuestionType === consts.SAP_QUESTION_TYPE_REGULAR);//.sort(OrderedBySubSectionId);
    let section = CreateQuestionAll(false, additionalQuestions.length > 0);

    section.isSelected = true;
    ConsoleLog(['GenerateQuestionSection,questions,regularQuestions,additionalQuestions,', CloneObj(sapQuestios), CloneObj(regularQuestions), CloneObj(additionalQuestions)]);
    //need to convert index from string to number in order to have the proper order.
    const subSectionIndex = [... new Set(sapQuestios.filter(item => item.QuestionType === consts.SAP_QUESTION_TYPE_REGULAR).map(data => data.SectionID))];//.sort();
    //regular questions 
    ConsoleLog(['GenerateQuestionSection,subSectionIndex', subSectionIndex]);
    subSectionIndex.forEach(subIndex => {
        let subSection = new SubSection(subIndex, false);
        subSection.subSectionTitle = subIndex;
        const questions = regularQuestions.filter(item => item.SectionID === subIndex);//.sort(OrderedBySequnceId);
        questions.forEach(que => {
            subSection.questions.push(new Question(que.SequenceID, que.QuestionDesc, '', false, '', que.QuestionInsText, QuestionType.Regular
            , true, subIndex, sectionType, "", false, que.EquipmentID
            , que.VehicleType, que.CodeGroup ?? '', que.Code, que.Charact, que.HardStop.length > 0, que.SequenceID, que.SectionID, que.NonFm));
        });
        section.regularQuestionCollection.push(subSection)
    });
    // ConsoleLog(['GenerateQuestionSection,regularQuestionCollection,', CloneObj(section.regularQuestionCollection)]);

    //additional question 
    let subSectionAdditional = new SubSection(consts.STR_ADDITION_ID, false);
    subSectionAdditional.subSectionTitle = GetTranslationAdditionalQues()
    let additionalIndex = 0;
    additionalQuestions.forEach(que => {
        const dataType = ConvertQueTypeToFrontEnd(que.DataType);
        subSectionAdditional.questions.push(new Question(que.SequenceID.length === 0 ? additionalIndex.toString() : que.SequenceID, que.QuestionDesc
        , '', false, '', que.QuestionInsText, dataType,
            false, consts.STR_ADDITION_ID, sectionType, que.DataType === SapQuestionType.Number ? `${que.LowerLimit}--${que.UpperLimit}` : ""
            , IsBooleanQuestion(que.DataType), que.EquipmentID
            , que.VehicleType, que.CodeGroup ?? '', que.Code, que.Charact, que.HardStop.length > 0, que.SequenceID, que.SectionID, que.NonFm));
        additionalIndex = additionalIndex + 1;
    });
    if (additionalQuestions.length === 0) {
        section.containAdditionalQuestion = false;
    }
    section.additionalQuestionCollection.push(subSectionAdditional)
    // ConsoleLog(['GenerateQuestionSection,additionalQuestionCollection,', CloneObj(section.additionalQuestionCollection)]);
    if (sapQuestios.length > 0) {
        // section.imageName = `${fileName}?v=${config.app.version}`
        section.imageName = `${QuestionsPicName(sapQuestios[0].QuestionTmpl)}`;
        section.thumbnailName = `${QuestionsThumbnailName(sapQuestios[0].QuestionTmpl)}`;
    }
    return section;
}

export const ParseQuestions = (data: any) => {

    // return res.results;
    // return new MaintainNotification("equipment01","desc01","evcr01",'2021-02-01',"12:30","status value");
    const questionTractor = CreateQuestionTractor(false);

    //testing data
    const questionChassis = CreateQuestionChassis(false, false);

    const questionVessel = CreateQuestionVessel(true, false);

    return { tractor: questionTractor, chassis: questionChassis, vessel: questionVessel }
}
export const GetQuestionSection = (questionSection: QuestionSectionType, obj: any) => {
    let section: IQuestionSection;
    switch (questionSection) {
        case QuestionSectionType.Tractor:
            section = obj.questionTractor;
            break;
        case QuestionSectionType.Chassis:
            section = obj.questionChassis;
            break;
        case QuestionSectionType.Vessel:
            section = obj.questionVessel;
            break;
    }
    return section;
}

export const IsNumberGood = (value: string, range: string) => {
    let isGood = false;
    if (IsNullOrEmpty(value) || IsNullOrEmpty(range)) {
        return isGood;
    }
    try {
        const min = Number(range.split('--')[0]);
        const max = Number(range.split('--')[1]);
        const numberValue = Number(value);
        ConsoleLog(['IsNumberGood', value, range, min, max, numberValue]);
        if (isNaN(min) || isNaN(max) || isNaN(numberValue)) {
            // return isGood;
            ConsoleLog(['IsNumberGood.Number is not a number.']);
        } else {
            isGood = numberValue >= min && numberValue <= max;
        }
        ConsoleLog(['IsNumberGood.isGood', isGood]);
    } catch (error) {
        ConsoleLog(['IsNumberGood.error', value, range])
    }
    return isGood;
};

export function ClearQuestionAnswer(question: Question) {
    ConsoleLog({ 'ClearQuestionAnswer': DeepClone(question) });
    // question.attachment = CreateEmptyFile()
    // question.fileName = ""
    // question.attachmentBase64 = "";
    question.issueDesc = ""
}

export const UpdateQuestionSectionHasDefect = (section: IQuestionSection) => {
    let questionSectionHasRegularDefect = false;
    let questionSectionHasAdditionalDefect = false;
    //Regular Section

    let containTopDefect = false;
    section.regularQuestionCollection.forEach((item: SubSection) => {
        let containDefect = false;
        item.questions.forEach((question: Question) => {
            if (question.notificationIsNeeded) {
                containDefect = true;
                questionSectionHasRegularDefect = true;
                if (question.hardStop) {
                    containTopDefect = true;
                }
            }
        });
        item.containDefect = containDefect;
    });
    //additinal section
    // additionalQuestionContainDefect
    
    section.additionalQuestionCollection.forEach((item: SubSection) => {
        let containAdditionalDefect = false;
        item.questions.forEach((question: Question) => {
            if (question.notificationIsNeeded) {
                containAdditionalDefect = true;
                questionSectionHasAdditionalDefect = true;
                if (question.hardStop) {
                    containTopDefect = true;
                }
            }
        });
        item.containDefect = containAdditionalDefect;
    });
    // section.containDefect = questionSectionHasRegularDefect || questionSectionHasAdditionalDefect;
    section.containDefect = questionSectionHasRegularDefect;
    section.additionalQuestionContainDefect = questionSectionHasAdditionalDefect;
    section.containTopDefect = containTopDefect;
};

export const EvcrHasDefect = (section: IQuestionSection) => {
    let hasDefect = true;
    //Regular Section
    for (const item of section.regularQuestionCollection) {
        var items = item.questions.filter((question: Question) => { question.notificationIsNeeded === true });
        if (items.length > 0) {
            return hasDefect
        }
    };
    // additionalQuestionContainDefect
    for (const item of section.additionalQuestionCollection) {
        var items = item.questions.filter((question: Question) => { question.notificationIsNeeded === true });
        if (items.length > 0) {
            return hasDefect
        }

    };
    return false;
};

export const EvcrHasQuestion = (section: IQuestionSection) => {
    let hasQuestion = false;
    if (!section.regularQuestionCollection) {
        return hasQuestion;
    }
    //Regular Section
    for (const item of section.regularQuestionCollection) {
        if (item.questions.length > 0) {
            hasQuestion = true;
            return hasQuestion;
        }
    };
    // additionalQuestionContainDefect
    for (const item of section.additionalQuestionCollection) {
        if (item.questions.length > 0) {
            hasQuestion = true;
            return hasQuestion;
        }
    };
    return hasQuestion;
};

export const EvcrFormValidate = (section: IQuestionSection) => {
    let validatedRegular = false;
    let validatedAdditional = false;
    //Regular Section
    if (section.regularQuestionCollection.length === 0) {
        validatedRegular = true;
    } else {
        for (const item of section.regularQuestionCollection) {
            if (!item.validated) {
                return false;
            }
        };
        validatedRegular = true;
    }

    // additionalQuestionContainDefect
    if (!section.containAdditionalQuestion) {
        validatedAdditional = true;
    } else {
        for (const item of section.additionalQuestionCollection) {
            if (item.questions.length > 0) {
                if (!item.validated) {
                    return false;
                }
            }
        };
        validatedAdditional = true;
    }

    return validatedRegular && validatedAdditional;
};

const validateAttachments = (attachments: IAttachment[]) => {
    let validated = false;
    let index = 0;
    attachments.forEach(item => {
        if (index === 0 && IsNullOrEmpty(item.attachmentBase64)) {
            return validated;
        }
        index++;
    });
    return true;;
};

export const EvcrSectionValidate = (subSection: ISubSection) => {
    const validated = false;
    for (const item of subSection.questions) {
        if (IsNullOrEmpty(item.answer)) {
            return validated;
        }
        if (item.notificationIsNeeded && IsNullOrEmpty(item.issueDesc)) {
            return validated;
        }
    };
    return true;
}

export const UpdateValidatedBySubSection = (subSection: ISubSection) => {
    for (const item of subSection.questions) {
        if (IsNullOrEmpty(item.answer)) {
            subSection.validated = false;
            return;
        }
        if (item.notificationIsNeeded && IsNullOrEmpty(item.issueDesc)) {
            subSection.validated = false;
            return;
        }
    };
    subSection.validated = true;
};

export const UpdateValidatedByQuestion = (section: IQuestionSection, question: IQuestion) => {
    let subSection: ISubSection;
    if (question.subSectionId === consts.STR_ADDITION_ID) {
        subSection = section.additionalQuestionCollection[0];
    } else {
        const regularSubSection = section.regularQuestionCollection.filter(item => item.subSectionId === question.subSectionId);
        if (regularSubSection.length === 0) {
            return;
        }
        subSection = regularSubSection[0]
    }
    for (const item of subSection.questions) {
        if (IsNullOrEmpty(item.answer)) {
            subSection.validated = false;
            return;
        }
        if (item.notificationIsNeeded && IsNullOrEmpty(item.issueDesc)) {
            subSection.validated = false;
            return;
        }
    };
    ConsoleLog({ "UpdateValidatedByQuestion": true })
    subSection.validated = true;
};

export const GetNextSubSectionId = (section: IQuestionSection, currentId: string) => {
    let subSectionId = "";
    if (currentId === consts.STR_ADDITION_ID) {
        return subSectionId;
    }
    if (section.regularQuestionCollection.length === 1) {
        return subSectionId;
    }

    const currentIndex = section.regularQuestionCollection.findIndex(item => item.subSectionId === currentId);
    if (currentIndex < 0) {
        return subSectionId;
    }
    if (currentIndex + 1 <= section.regularQuestionCollection.length - 1) {
        subSectionId = section.regularQuestionCollection[currentIndex + 1].subSectionId;
    }

    return subSectionId;
};

export const GetPreviousSubSectionId = (section: IQuestionSection, currentId: string) => {
    let subSectionId = "";
    if (currentId === consts.STR_ADDITION_ID) {
        return subSectionId;
    }
    if (section.regularQuestionCollection.length === 1) {
        return subSectionId;
    }
    const currentIndex = section.regularQuestionCollection.findIndex(item => item.subSectionId === currentId);
    if (currentIndex < 0) {
        return subSectionId;
    }

    if ((currentIndex - 1) > -1) {
        subSectionId = section.regularQuestionCollection[currentIndex - 1].subSectionId;
    }

    return subSectionId;
};


export const SetFirtLastSubsection = (section: IQuestionSection, currentId: string, isRegular: boolean) => {
    let notLast = false;
    let notFirst = false;
    let currentIndex = -1;
    if (isRegular) {
        currentIndex = section.regularQuestionCollection.findIndex(
            (item: any) => item.subSectionId === currentId
        );
        if (currentIndex < 0) {
            notFirst = false;
            notLast = false;
        } else {
            // last
            if (currentIndex + 1 <= section.regularQuestionCollection.length - 1) {
                notLast = true;
            } else if (section.containAdditionalQuestion) {
                notLast = true;
            }
            // first
            if ((currentIndex - 1) > -1) {
                notFirst = true;
            }
        }
    } else {
        currentIndex = section.additionalQuestionCollection.findIndex(
            (item: any) => item.subSectionId === currentId
        );
        if (currentIndex < 0) {
            notFirst = false;
            notLast = false;
        } else {
            // last: only have one additional subSection for additional questions
            notLast = false;
            // first :it it has regular questions, the previous sub section should the the last regular subsection.
            if (section.regularQuestionCollection.length > 0) {
                notFirst = true;
            }
        }
    }
    return { notFirst, notLast };
};


export const GetLastRegualrSubSectionId = (section: IQuestionSection) => {
    let subSectionId = "";
    if (section.regularQuestionCollection.length === 0) {
        return subSectionId;
    }
    subSectionId = section.regularQuestionCollection[section.regularQuestionCollection.length - 1].subSectionId;
    return subSectionId;
};