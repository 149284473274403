<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ this.question.desc }}</v-card-title>
          <v-card-text>
            <v-card-title v-t="'ml_114651'">Introduction</v-card-title>
            <p>{{ this.question.instruction }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="GoBack($router)" :disabled="btnDisable">
              {{ okText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { Question } from "@/models/question-section/question";
import { ConsoleLog, Timeout, Text } from "@/utility";
import { GoBack } from "@/utility/ui-utility";
export default Vue.extend({
  props: {
    questionData: Object,
  },
  components: {},
  data() {
    return {
      btnDisable: true,
      okText: `${Text("ml_114657", "Okay")}`,
      question: this.questionData,
    };
  },
  computed: {},
  methods: {
    Text,
    GoBack,
    async showBtnText() {
      // for (var i = 5; i > 0; i--) {
      //   this.okText = `${Text("", "Okay")}(${i})`;
      //   await Timeout(1000);
      // }
      // this.okText = `${Text("", "Okay")}`;
      this.btnDisable = false;
    },
  },
  mounted() {
    ConsoleLog({ "RecordQuestionGood.vue.mounted": this.question });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.showBtnText();
  },
});
</script>

<style></style>
