import { IQuestionPost, QuestionPost } from "./question-post";

export interface IPrintFormPost {
    TripType: string;
    LBShellTrip: string;
    PrePost: string;
    Plant: string;
    Driver1: string;
    // Driver1Name: string;
    Driver2: string;
    TractorLicense: string;
    ThirdPartyInd: string;
    ChassisLicense: string;
    LBShellID: string;
    OdoMeter: string;
    // TimeStamp: string;
    Date: string;
    Time: string;
    signImage:string;
    country:string;
    language:string;
    plantName:string;
    overviewImage:string;
    uri:string;
    customPlant:string;
    eVCRRecordItemSet: IQuestionPost[];
}

export class PrintFormPost implements IPrintFormPost {
    TripType: string;
    LBShellTrip: string;
    PrePost: string;
    Plant: string;
    Driver1: string;
    Driver1Name: string;
    Driver2: string;
    TractorLicense: string;
    ThirdPartyInd: string;
    ChassisLicense: string;
    LBShellID: string;
    OdoMeter: string;
    // TimeStamp: string;
    Date: string;
    Time: string;
    signImage:string;
    country:string;
    language:string;
    plantName:string;
    overviewImage:string;
    uri:string;
    customPlant:string;
    eVCRRecordItemSet: IQuestionPost[];
    constructor(
        TripType: string,
        LBShellTrip: string,
        PrePost: string,
        Plant: string,
        Driver1: string,
        Driver1Name: string,
        Driver2: string,
        TractorLicense: string,
        ThirdPartyInd: string,
        ChassisLicense: string,
        LBShellID: string,
        OdoMeter: string,
        // TimeStamp: string
        Date: string,
        Time: string,
        signImage:string,
        country:string,
        language:string,
        plantName:string,
        overviewImage:string,
        uri:string,
        customPlant:string,
    ) {
        this.TripType = TripType;
        this.LBShellTrip = LBShellTrip;
        this.PrePost = PrePost;
        this.Plant = Plant;
        this.Driver1 = Driver1;
        this.Driver1Name = Driver1Name;
        this.Driver2 = Driver2;
        this.TractorLicense = TractorLicense;
        this.ThirdPartyInd = ThirdPartyInd;
        this.ChassisLicense = ChassisLicense;
        this.LBShellID = LBShellID;
        this.OdoMeter = OdoMeter;
        // this.TimeStamp = TimeStamp;
        this.Date = Date;
        this.Time = Time;
        // this.eVCRRecordItemSet = eVCRRecordItemSet;
        this.signImage=signImage;
        this.country=country;
        this.language=language;
        this.plantName=plantName;
        this.overviewImage=overviewImage;
        this.uri=uri;
        this.customPlant=customPlant;
        this.eVCRRecordItemSet = [];
    }
}

