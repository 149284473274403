<template>
  <!-- class="margin-0" -->
  <v-row>
    <v-col :cols="(!showQuestionIcon && !showEditIcon) ? 12:''" class="padding-0">
      <v-select
        :value="value"
        :items="items"
        :rules="rules"
        :label="label"
        item-text="itemText"
        item-value="itemValue"
        v-on:input="onChanging"
        v-on:change="onUserChanging"
        :readonly="readonly"
      >
        <!-- <v-icon src="questionIcon" alt="Question Introduction" title="Question Introduction2" /> -->

        <template #label v-if="required">
          <span class="red-error"><strong>* </strong></span>{{ label }}
        </template>
      </v-select>
    </v-col>
    <v-col cols="1" class="padding-0 alignMiddle" v-show="showQuestionIcon" >
      <v-icon @click="questionCick" v-show="showQuestionIcon" color="darken-1">{{
        icons.mdiMapMarkerQuestion
      }}</v-icon>
    </v-col>
    <v-col cols="1" class="padding-0 alignMiddle " v-show="showEditIcon" >
      <v-icon @click="editCick" v-show="showEditIcon" color="darken-1">{{
        icons.mdiPencil
      }}</v-icon>
        <!-- left=true -->
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { ISelectItem } from "@/models/select-item";
// import {questionIcon} from "@/assets/image/question-40.png"
import { ConsoleLog, Timeout } from "@/utility";
import Vue from "vue";
import { mdiPencil, mdiMapMarkerQuestion } from "@mdi/js";

export default Vue.extend({
  props: {
    value: String || Number,
    label: String,
    items: [],
    rules: [],
    required: Boolean,
    readonly: { type: Boolean, default: false },
    showAsLabel: { type: Boolean, default: false },
    showQuestionIcon: { type: Boolean, default: false },
    showEditIcon: { type: Boolean, default: false },
  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiMapMarkerQuestion,
    },
  }),

  computed: {},
  methods: {
    ConsoleLog,
    onChanging(value: string) {
      this.$emit("input", value);
    },
    onUserChanging(value: any) {
      ConsoleLog({ onUserChanging: { value: this.items } });
      this.$emit("userChange", value);
    },
    editCick(e: any) {
      // ConsoleLog({ editCick: e });
      this.$emit("userEditClick", e);
    },
    questionCick(e: any) {
      // ConsoleLog({ questionCick: e });
      this.$emit("userQuestionClick", e);
    },
  },
  mounted(): void {
    const self = this as any;
    if (self.items && self.items.length > 0) {
      self.items.forEach((item) => {
        // ConsoleLog(item);
      });
    } else {
      // ConsoleLog(["self.items", self.items]);
    }
  },
});
</script>
