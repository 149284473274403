<template>
  <!-- <v-container > -->
  <v-row v-show="recordQuestionSummaryRegularData.containDefect">
    <v-col cols="12">
    <!-- <v-card-subtitle>{{ recordQuestionSummaryRegularData.subSectionId }}</v-card-subtitle> -->
    <template v-for="(question, index) in recordQuestionSummaryRegularData.questions">
      <template v-if="question.notificationIsNeeded">
        <RecordQuestionSummaryRegularItem
          :key="index"
          :record-question-summary-regular-item-data="question"
        />
      </template>
    </template>
    </v-col>
  </v-row>
  <!-- </v-container> -->
</template>

<script lang="ts">
import Vue from "vue";
// in full builds helpers are exposed as Vuex.mapState
import { mapState } from "vuex";
import { PageName, RegularAnswer } from "@/models/enums";
// import RecordQuestionHead from './RecordQuestionHead.vue'
// import RecordQuestionRegular from './RecordQuestionRegular.vue'
import RecordQuestionSummaryRegularItem from "./RecordQuestionSummaryRegularItem.vue";
// import HomePage from "../components/HomePage.vue";

export default Vue.extend({
  components: {
    // RecordQuestionHead,
    // RecordQuestionRegular,
    // RecordQuestionAdditional,
    RecordQuestionSummaryRegularItem,
    // HomePage,
  },
  data() {
    return {
      RegularAnswer,
    };
  },
  props: {
    recordQuestionSummaryRegularData: {
      type: Object,
    },
  },
  computed: {},
  methods: {
    // next() {
    //   this.step += 1
    // },
    // previous() {
    //   this.step -= 1
    // },
    submitOrder() {
      this.$router.push({ name: "thankyou" });
    },
  },
});
</script>

<style></style>
