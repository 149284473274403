<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <!-- <v-card-title>{{ recordQuestionHeadData.title }}</v-card-title> -->
          <v-card-text>
            <v-row>
              <v-col>
                <v-card>
                  <v-img
                    @click="zoom()"
                    class="div-align-center"
                    :src="VehiclePath(thumbnailName)"
                    :height="photoHeight"
                    :width="photoWidth"
                    @load="loadedPic"
                    ref="photoThumb"
                  ></v-img>

                  <zoom-in :section="recordQuestionHeadData" ref="zoomIn"></zoom-in>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
// import image from '../assets/image/PictureTractor.jpg'; // with import
import { VehiclePath, GetPicSize } from "@/utility/ui-utility";
import ZoomIn from "./ZoomIn.vue";
import { ConsoleLog } from "@/utility";
export default Vue.extend({
  components: { ZoomIn },
  computed: {},
  props: {
    recordQuestionHeadData: {
      type: Object,
    },
    thumbnailName: { type: String, default: "" },
  },

  data: () => ({
    picFileName: "",
    showZoom: false,
    photoHeight: 0,
    photoWidth: 0,
  }),
  methods: {
    VehiclePath,
    zoom(): void {
      const self = this as any;
      self.showZoom = true;
      self.$refs.zoomIn.updateShowDialog(self.showZoom);
    },
    loadedPic(imageEvent: any) {
      ConsoleLog({ loadPic: this.$refs.photoThumb, imageEvent: imageEvent });
      const img = this.$refs.photoThumb as any;
      if (img.image) {
        this.photoWidth = img.image.width;
        this.photoHeight = img.image.height;
        ConsoleLog({ photoWidth: this.photoWidth, photoHeight: this.photoHeight });
      }
    },
    fullImage(src: string) {
      let loaded = false;
      function loadHandler() {
        if (loaded) {
          return;
        }
        loaded = true;

        ConsoleLog({ "fullImage.loadHandler": "loaded" });
        /* your code */
      }
      const img = this.$refs.photoThumb as any;
      img.addEventListener("load", loadHandler);
      img.src = src;
      img.style.display = "block";
      if (img.complete) {
        loadHandler();
      }
    },
    picWidth() {
      const self = this as any;
      return GetPicSize(self.thumbnailName).width;
    },
    picHeight() {
      const self = this as any;
      return GetPicSize(self.thumbnailName).height;
    },
  },
  beforeMount() {
    // this.picFileName = this.thumbnailName;
    // this.fullImage(VehiclePath(this.thumbnailName));
  },
});
</script>
