import { CloneObj, ConsoleLog } from "."
import * as consts from "@/models/consts"
import { StoreMutation, StoreAction } from '@/store/store-mapping';

export function saveStatePlugin(store) {
  store.subscribe((mutation, state) => {
    // const mutationList = [StoreMutation.emUpdateTripInfo, StoreMutation.emUpdateQuestionTractor,
    // StoreMutation.emUpdateQuestionChassis, StoreMutation.emUpdateQuestionVessel,
    // StoreMutation.emUpdateQuestion, StoreMutation.emInitializeState];
    const mutationList = [StoreMutation.emUpdateTripInfo, StoreMutation.emUpdateQuestions,
    StoreMutation.emUpdateQuestion, StoreMutation.emInitializeState, StoreMutation.emClearTripId];
    if (!mutationList.includes(mutation.type)) {
      return;
    }
    console.log('saveStatePlugin.subscribe', mutation, CloneObj(state.evcrModule))
    localStorage.setItem(consts.EVCR_DATA_KEY, JSON.stringify(state.evcrModule))
  });
  store.subscribeAction({
    after: (action, state) => {
      const actionList = [StoreAction.emGetQuestions, StoreAction.emInitialData];
      if (!actionList.includes(action.type)) {
        return;
      }
      console.log('saveStatePlugin.subscribeAction', action, CloneObj(state.evcrModule));
      localStorage.setItem(consts.EVCR_DATA_KEY, JSON.stringify(state.evcrModule));
    },
  });
}
