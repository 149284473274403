<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title v-t="'ml_114640'">Recent Vehicle Condition</v-card-title>
          <v-card-actions v-if="IsLocalMode()">
            <v-btn @click="$router.go(-1)" color="primary"> Back </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onNext" v-t="'ml_114639'"> Next </v-btn>
          </v-card-actions>
          <template v-for="(notification, index) in notifications">
            <v-card-text :key="index">
              <div class="align-center font-bolder">
                {{ Text("ml_114722", "Item") + (index + 1) }}
              </div>
              <form-input
                v-model="notification.vehicleEquipment"
                readonly
                :label="Text('ml_114641', 'Vehicle/Equipment')"
              ></form-input>
              <form-input
                v-model="notification.notificationId"
                :label="Text('ml_114903', 'Notification ID')"
                readonly
              ></form-input>
              <form-input
                v-model="notification.desc"
                :label="Text('ml_114642', 'Noti. Description')"
                readonly
              ></form-input>
              <form-textarea
                v-model="notification.defectDesc"
                :label="Text('ml_114904', 'Defect Description')"
                :readonly="disable"
                rows="2"
              ></form-textarea>
              <form-input
                v-model="notification.date"
                :label="Text('ml_114643', 'Notification Date')"
                readonly
              ></form-input>
              <form-input
                v-model="notification.time"
                :label="Text('ml_114644', 'Notification Time')"
                readonly
              ></form-input>
              <form-input
                v-model="notification.status"
                :label="Text('ml_114645', 'Status')"
                readonly
              ></form-input>
            </v-card-text>
          </template>

          <p v-if="errorMsg.length > 0" class="red-error">{{ errorMsg }}</p>
          <v-card-actions>
            <v-btn @click="$router.go(-1)" color="primary" v-t="'ml_114638'">
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onNext" v-t="'ml_114639'"> Next </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { PageName } from "@/models/enums";
import { StoreAction, StoreGetter } from "@/store/store-mapping";
import { CreateNewUUID, Text } from "@/utility";
import Vue from "vue";
import { mapGetters } from "vuex";
import FormInput from "@/components/FormInput.vue";
import { QuestionFilter } from "@/models/question-section";
import { GoOverview, IsLocalMode } from "@/utility/ui-utility";
import FormTextarea from "@/components/FormTextarea.vue";

import config from "@/config/config.json";

export default Vue.extend({
  components: { FormInput, FormTextarea },
  data() {
    return {
      errorMsg: "",
      itemIndex: 1,
      disable: true,
    };
  },
  computed: {
    ...mapGetters({
      notifications: StoreGetter.emMaintainNotifications,
    }),
  },
  methods: {
    Text,
    CreateNewUUID,
    IsLocalMode,
    onNext() {
      this.errorMsg = "";
      GoOverview(this.$router);
    },
  },
  mounted(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
});
</script>
