import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './I18n';
import * as consts from "@/models/consts"

import VueMask from 'v-mask'

import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);

Vue.use(VueMask)

Vue.config.productionTip = false
Vue.prototype.$isDebug = false;
Vue.prototype.$lanCode = consts.STR_DEFAULT_LANG;

// Vue.config.errorHandler = (err, vm, info) => {
//   const pageName = router.currentRoute.name;
//   ConsoleLog([pageName, 'confi.errorHandler']);
//   ConsoleLog([err, info]);
//   const self = vm as any;
//   ConsoleLog(CopyByJson(self.$store.getters[StoreGetter.umUserProfile]));
//   // user doesn't log on to system
//   if (!self.$store.getters[StoreGetter.umUserProfile]) {
//     router.push({ name: PageName.Home });
//     return;
//   }
//   if (pageName && !PagesNotForAddingAsset.includes(pageName)) {
//     const asset = self.$store.state.assetModule.asset;
//     if (asset && asset.assetDevices[0] === undefined) {
//       router.push({ name: PageName.AssetTemplateList });
//     }
//   }
// };

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
