var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[(_vm.recordQuestionAdditionalData.questions.length > 0)?[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{attrs:{"id":_vm.STR_SUBSECTION_ID + _vm.STR_ADDITION_ID}},[_vm._v(_vm._s(_vm.recordQuestionAdditionalData.subSectionTitle))]),_c('div',[_vm._l((_vm.recordQuestionAdditionalData.questions),function(question,index){return [(question.questionType === _vm.QuestionType.Char)?[_c('form-textarea',{key:index,attrs:{"rules":_vm.rules.inputString,"label":question.desc,"rows":"3","required":""},on:{"userChange":function($event){return _vm.onUpdateCharQuestion(question)}},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}})]:(question.questionType === _vm.QuestionType.Number)?[_c('form-input',{key:index,attrs:{"rules":_vm.rules.inputNumber,"label":question.desc,"required":""},on:{"userChange":(newValue) => _vm.onUpdateQuestion(newValue, question)},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}})]:(
                  question.questionType === _vm.QuestionType.Boolean &&
                  question.questionTypeBooleanValue
                )?[_c('form-regular',{key:index,ref:'regularQ' + question.sequenceId,refInFor:true,attrs:{"rules":_vm.rules.InputSelect,"label":question.desc,"required":"","items":_vm.RegularItems(),"showEditIcon":question.notificationIsNeeded,"showQuestionIcon":!_vm.IsNullOrEmpty(question.instruction)},on:{"userChange":(newValue, originalValue) =>
                      _vm.goToquestionRegularDetail(
                        newValue,
                        question,
                        originalValue,
                        question.sequenceId
                      ),"userEditClick":(e) => _vm.editClick(e, question),"userQuestionClick":(e) => _vm.questionClick(e, question)},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}})]:_vm._e()]})],2)],1)],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }