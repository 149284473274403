import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import english from '@/locales/en-US.json'
import zhcn from '@/locales/zh-CN.json'
import { STR_DEFAULT_LANG } from './models/consts'
import { ConsoleLog, ConvertBrowserLanguage } from './utility'
// import english from '../public/locales/en-US.json'

Vue.use(VueI18n)

const messages = {
    'en-US': english,
    // STR_LANG_ZH_CN: zhcn
}

const numberFormatsTemplate = {
    '2dec': { minimumFractionDigits: 2, maximumFractionDigits: 2 },
    '3dec': { minimumFractionDigits: 3, maximumFractionDigits: 3 },
    '4dec': { minimumFractionDigits: 4, maximumFractionDigits: 4 }
}

const dateTimeFormatsTemplate = {
    short: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    },
    med: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    },
    long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
    }
} as unknown as VueI18n.DateTimeFormats;

var numberFormats = {
    'en-US': numberFormatsTemplate
}

var dateTimeFormats = {
    'en-US': dateTimeFormatsTemplate
}

const i18n = new VueI18n({
    locale: STR_DEFAULT_LANG,
    fallbackLocale: STR_DEFAULT_LANG,
    dateTimeFormats,
    numberFormats,
    messages,
});
// console.log("i18n.messages", messages, i18n);
(i18n as any).loadedLanguages = [STR_DEFAULT_LANG]

function setI18nLanguage(lang: string) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html')?.setAttribute('lang', lang)

    return lang
}

(i18n as any).loadLanguageAsync = (lang: string) => {
    // console.log("loadLanguageAsync start", lang);
    if (i18n.locale !== lang) {
        if (!(i18n as any).loadedLanguages.includes(lang)) {
            console.log("loadLanguageAsync", lang);
            return import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}`)
                .then(msgs => {
                    numberFormats[lang] = numberFormatsTemplate
                    dateTimeFormats[lang] = dateTimeFormatsTemplate
                    i18n.setLocaleMessage(lang, msgs);
                    (i18n as any).loadedLanguages.push(lang)
                    console.log("loadLanguageAsync.push", lang, (i18n as any).loadedLanguages, msgs);
                    return setI18nLanguage(lang)
                })
                .catch(() => {
                    lang = STR_DEFAULT_LANG
                })
        }

        return Promise.resolve(setI18nLanguage(lang))
    }

    return Promise.resolve(lang)
}

export default i18n
