import { Commit } from 'vuex'
import { BaseDataLoadingAction } from '..'
import { ConsoleLog, HandleAPIException, Text } from '@/utility'
import { CreateEvcr, IEvcr } from '@/models/evcr'
import { ITripInfo, TripInfo } from '@/models/trip-info'
import { GetLastEvcr, GetLastEvcrByDriver } from '@/services/evcr-svc'
import { HandleSapReturnCode } from '@/utility/ui-utility'
import { Question } from '@/models/question-section/question'
import { StoreMutation } from '../store-mapping'

interface IState {
  lastEvcr: IEvcr;
  lastEvcrByDriver: any;
  questions: Question[];
  tractor: string;
  errorMsg: string;
}

export const lastEvcrModule = {
  namespaced: true,
  state: () => ({
    lastEvcr: CreateEvcr(),
    lastEvcrByDriver: TripInfo,
    questions: [] as Question[],
    errorMsg: '',
    tractor: ""
  }),
  getters: {
    lastEvcrTrip: (state: IState) => {
      return state.lastEvcr.tripInfo;
    },
    lastEvcrTripByDriver: (state: IState) => {
      return state.lastEvcrByDriver.tripInfo;
    },
    getTractor: (state: IState) => {
      return state.tractor;
    },
    questions: (state: IState) => {
      return state.questions;
    },
  },
  mutations: {
    resetLastVcr: (state: IState) => {
      state.lastEvcr = CreateEvcr();
      state.questions = [];
      state.errorMsg = "";
      state.tractor = "";
    },
    updateTractor: (state: IState, { tractorData }: { tractorData: string }) => {
      state.tractor = tractorData;
    },
    updateLastEvcrByDriver: (state: IState, tripInfo: TripInfo) => {
      state.lastEvcrByDriver = tripInfo;
    }
  },
  actions: {
    getLastEvcr: async (
      { state, commit }: { state: IState; commit: Commit },
      { trip }: { trip: ITripInfo }
    ) => {
      state.errorMsg = ''
      await BaseDataLoadingAction(commit, async () => {
        console.time("getLastEvcr");
        const res = await GetLastEvcr(trip)
        if (res.isSuccess === false) {
          const msg = HandleSapReturnCode(res.data?.error, Text('ml_114695', "Encountered error when getting the last eVCR,please try again."))
          ConsoleLog({ "getLastEvcr.res.isSuccess === false": res.data });
          HandleAPIException(res.data, state, msg);
          return;
        } else {
          ConsoleLog({ "getLastEvcr": res.data });
          state.lastEvcr.tripInfo = res.data.tripInfo;
          state.questions = res.data.questions;
          if (res.data.questions.length === 0) {
            state.errorMsg = Text('ml_114928', "There is no last eVCR for the selected Tractor.")
          }
        }
        console.timeEnd("getLastEvcr");
      })
    },
    getLastEvcrByDriver: async (
      { state, commit }: { state: IState; commit: Commit },
      { trip }: { trip: ITripInfo }
    ) => {
      state.errorMsg = ''
      await BaseDataLoadingAction(commit, async () => {
        console.time("getLastEvcrByDriver");
        const res = await GetLastEvcrByDriver(trip)
        if (res.isSuccess === false) {
          const msg = HandleSapReturnCode(res.data?.error, Text('ml_114695', "Encountered error when getting the last eVCR,please try again."))
          ConsoleLog({ "getLastEvcrByDriver.res.isSuccess === false": res.data });
          HandleAPIException(res.data, state, msg);
          return;
        } else {
          ConsoleLog({ "getLastEvcrByDriver": res.data });
          commit(StoreMutation.emUpdateLastEvcrByDriver, { tripInfo: res.data.result[0] }, { root: true });
        }
        console.timeEnd("getLastEvcrByDriver");
      })
    }
  },
}
