import { IMaintainNotification, MaintainNotification, INotificationFilter } from "@/models/maitain-notification";
import { ConsoleLog, Timeout } from "@/utility";
import config from '@/config/config.json';
import { CreateQuestionTractor, CreateQuestionChassis, CreateQuestionVessel, IQuestionFilter } from "@/models/question-section";
import { ParseQuestions } from "./data-process/questions-process";
import { serviceAddress } from "./service-address";
import { AsyncFetchGet, AsyncFetchGetByToken, AsyncFetchPost } from "./shared-service";
import { CustomResponse } from "@/models/service";
import { GeneratePlantList } from "./data-process/plant-process";
import { GenerateEquipmentNotifications, GenerateFive, GenerateLastEvcr, GenerateVehicleList, GenerateVehicleListTest, HandlePostResponse } from "./data-process/basic-process";
import { GenerateQuestions } from "./data-process/questions-process";
import { IEvcrPost } from "@/models/evcr-post";
import { ITripInfo } from "@/models/trip-info";
import { PrintFormPost, IPrintFormPost } from "@/models/evcr-post/print-form-post";

export async function SetMaintainNotification(filter: INotificationFilter) {

    const url = serviceAddress.GenerateEquipmentNotificationGetUrl(filter);
    const response = await AsyncFetchGet(url);
    if (response.isSuccess === false) {
        return new CustomResponse(response, false);
    }
    // await Timeout(1000);
    const returnVal = GenerateEquipmentNotifications(response);
    return new CustomResponse(returnVal);

    // notifications.push(new MaintainNotification("equipment01", "desc01", "evcr01", '2021-02-01', "12:30", "status value"));
    // notifications.push(new MaintainNotification("equipment02", "desc02", "evcr01", '2021-02-02', "12:40", "status value2"));
}

export async function GetQuestions(filter: IQuestionFilter) {
    const url = serviceAddress.GenerateQuestionGetUrl(filter);
    const response = await AsyncFetchGet(url);
    if (response.isSuccess === false) {
        return new CustomResponse(response, false);
    }
    const returnVal = GenerateQuestions(response, filter);
    return new CustomResponse(returnVal);
}

export async function GetEvcrValidation(filter: ITripInfo) {
    const url = serviceAddress.GenerateValidateEvcrGetUrl(filter);
    const response = await AsyncFetchGet(url);
    if (response.isSuccess === false) {
        return new CustomResponse(response, false);
    }
    return new CustomResponse(response.result);
}

export async function PostNewEvcr(data: IEvcrPost) {
    const url = serviceAddress.GeneratePostNewEvcrUrl();
    const response = await AsyncFetchPost(url, data);
    if (response.isSuccess === false) {
        return new CustomResponse(response, false);
    }
    return new CustomResponse(response);
}

//export async function PostNewEvcrSign(data: IEvcrPost) {
//    const url = serviceAddress.GeneratePostNewEvcrSignUrl();
//    const response = await AsyncFetchPost(url, data);
//    if (response.isSuccess === false) {
//        return new CustomResponse(response, false);
//    }
//    return new CustomResponse(response);
//}

export async function PostNewEvcrSign(data: IPrintFormPost) {
    const url = serviceAddress.GeneratePostNewEvcrSignUrl();
    const response = await AsyncFetchPost(url, data);
    if (response.isSuccess === false) {
        return new CustomResponse(response, false);
    }
    return new CustomResponse(response);
}

export async function PostEvcrImages(data: IEvcrPost) {
    try {
        const url = serviceAddress.GeneratePostEvcrImagesUrl();
        const response = await AsyncFetchPost(url, data);
        if (response.isSuccess === false) {
            return new CustomResponse(response, false);
        }
        return new CustomResponse(response);
    } catch (error) {
        ConsoleLog({ 'PostEvcrImages.error': error, "data": data })
        return new CustomResponse(error, false);
    }
}

export async function GetLastEvcr(filter: ITripInfo) {
    const url = serviceAddress.GenerateLastEvcrGetUrl(filter);
    const response = await AsyncFetchGet(url);
    if (response.isSuccess === false) {
        return new CustomResponse(response, false);
    }
    const returnVal = GenerateLastEvcr(response, filter);
    return new CustomResponse(returnVal);
}

export async function GetLastEvcrByDriver(filter: ITripInfo) {
    const url = serviceAddress.GenerateLastEvcrGetUrlByDriver(filter);
    const response = await AsyncFetchGet(url);
    return new CustomResponse(response);
}

export async function GetFive() {
    const url = serviceAddress.GenerateGetFiveUrl();
    const response = await AsyncFetchGetByToken(url);
    if (response.isSuccess === false) {
        return new CustomResponse(response, false);
    }
    const returnVal = GenerateFive(response);
    return new CustomResponse(returnVal);
}

export async function GetVehicleList(countryCode:string) {
    // countryCode ='CN'
    const url = serviceAddress.GenerateCounryVehiclesGetUrl(countryCode);
    const response = await AsyncFetchGet(url);
    if (response.isSuccess === false) {
        return new CustomResponse(response, false);
    }
    const returnVal = GenerateVehicleList(response);
    // const returnVal = GenerateVehicleListTest(response);
    return new CustomResponse(returnVal);
}
