import { EvcrPost, IEvcrPost } from "@/models/evcr-post";
import { PrintFormPost, IPrintFormPost } from "@/models/evcr-post/print-form-post";
import { ImagePost } from "@/models/evcr-post/image-post";
import { IQuestionPost, QuestionPost } from "@/models/evcr-post/question-post";
import { IQuestionSection, QuestionSection } from "@/models/question-section";
import { IQuestion } from "@/models/question-section/question";
import { TripInfo } from "@/models/trip-info";
import { ConsoleLog, IsNullOrEmpty, NullToEmpty, PadLeftZero } from "@/utility";
import * as consts from "@/models/consts"
import { ImgeApiMode, TripType } from "@/models/enums";
import { IAttachment } from "@/models/question-section/attachment";
import { QuestionSectionType, QuestionType, TractorThirdPartyCategory, ChassisThirdPartyCategory, VesselThirdPartyCategory } from "@/models/enums";

export const GeneratePostEvcrOld = (trip: TripInfo, tractorSection: IQuestionSection, chassisSection: IQuestionSection, vesselSection: IQuestionSection) => {
    const evcrPost = new EvcrPost(trip.tripType, trip.lbsTripId, trip.prePost, trip.plantId
        , trip.driver1
        , trip.driver1Name
        , trip.driver2
        , trip.tractor, trip.thirdParty ? consts.STR_THIRD_PARTY_TRUE : "", trip.chassis, trip.vessel
        , trip.odometer, new Date(trip.date + ' ' + trip.time).toISOString().substring(0, 19), ''
        ,trip.isTractorThirdParty ? 'X' : ''
        ,trip.isChassisThirdParty ? 'X' : ''
        ,trip.isVesselThirdParty ? 'X' : ''
        ,trip.country, trip.tractor3rdPartyVehType,
        trip.chassis3rdPartyVehType,
        trip.vessel3rdPartyVehType)
    GenerateSubSectionQuestions(evcrPost.eVCRRecordItemSet, tractorSection);
    if (chassisSection.isSelected) {
        GenerateSubSectionQuestions(evcrPost.eVCRRecordItemSet, chassisSection);
    }
    if (vesselSection.isSelected) {
        GenerateSubSectionQuestions(evcrPost.eVCRRecordItemSet, vesselSection);
    }
    return evcrPost;
}


export const GeneratePostEvcrOldVersion2 = (trip: TripInfo, section: IQuestionSection) => {
    const postDateTime = new Date((trip.date + ' ' + trip.time).replace(/-/g, "/")).toISOString().substring(0, 19);
    const evcrPost = new EvcrPost(trip.tripType, trip.lbsTripId, trip.prePost, trip.plantId
        , trip.driver1, trip.driver1Name
        , trip.driver2, trip.tractor, trip.thirdParty ? consts.STR_THIRD_PARTY_TRUE : "", trip.chassis
        , trip.vessel, trip.odometer, postDateTime, ''
        ,trip.isTractorThirdParty ? 'X' : ''
        ,trip.isChassisThirdParty ? 'X' : ''
        ,trip.isVesselThirdParty ? 'X' : ''
        ,trip.country, trip.tractor3rdPartyVehType,
        trip.chassis3rdPartyVehType,
        trip.vessel3rdPartyVehType)
    GenerateSubSectionQuestions(evcrPost.eVCRRecordItemSet, section);
    return evcrPost;
};

export const GeneratePostEvcrHeader = (trip: TripInfo) => {
    // const postDateTime = new Date((trip.date + ' ' + trip.time).replace(/-/g, "/")).toISOString().substring(0, 19);
    // const date = trip.date.replace(/-/g, '');
    // trip.date = '2022-4-2';
    const dateArray = trip.date.split('-');
    if (dateArray.length !== 3) {
        throw Error('Date is invalid');
    }
    if (dateArray[1].length < 2) {
        dateArray[1] = '0' + dateArray[1];
        ConsoleLog({ 'GeneratePostEvcrHeader.dateArray[1]': dateArray[1] });
    }
    if (dateArray[2].length < 2) {
        dateArray[2] = '0' + dateArray[2];
        ConsoleLog({ 'GeneratePostEvcrHeader.dateArray[2]': dateArray[2] });
    }
    const date = dateArray[0] + dateArray[1] + dateArray[2];

    //Handle time
    ConsoleLog({ 'GeneratePostEvcrHeader.time': trip.time });
    // trip.time = '9:8'
    const timeArray = trip.time.split(':');
    if (timeArray.length !== 2) {
        throw Error('time is invalid');
    }
    if (timeArray[0].length < 2) {
        timeArray[0] = '0' + timeArray[0];
        ConsoleLog({ 'GeneratePostEvcrHeader.timeArray[0]': timeArray[0] });
    }
    if (timeArray[1].length < 2) {
        timeArray[1] = '0' + timeArray[1];
        ConsoleLog({ 'GeneratePostEvcrHeader.timeArray[1]': timeArray[1] });
    }
    const time = timeArray[0] + timeArray[1] + '00';
    // const time = trip.time.replace(':', '') + '00';
    const evcrPost = new EvcrPost(trip.tripType, trip.tripType === TripType.LB ? trip.lbsTripId : trip.pgTripId, trip.prePost,''
        , trip.driver1, trip.driver1Name
        , trip.driver2, NullToEmpty(trip.tractor), trip.thirdParty ? consts.STR_THIRD_PARTY_TRUE : "", NullToEmpty(trip.chassis)
        , NullToEmpty(trip.vessel), trip.odometer, date, time
        ,trip.isTractorThirdParty ? 'X' : ''
        ,trip.isChassisThirdParty ? 'X' : ''
        ,trip.isVesselThirdParty ? 'X' : ''
        ,trip.country, trip.tractor3rdPartyVehType,
        trip.chassis3rdPartyVehType,
        trip.vessel3rdPartyVehType)
    return evcrPost;
};

export const GeneratePostEvcrHeaderUtc = (trip: TripInfo) => {
    // const postDateTime = new Date((trip.date + ' ' + trip.time).replace(/-/g, "/")).toISOString().substring(0, 19);
    // const date = trip.date.replace(/-/g, '');
    // trip.date = '2022-4-2';
    const dateArray = trip.date.split('-');
    if (dateArray.length !== 3) {
        throw Error('Date is invalid');
    }
    if (dateArray[1].length < 2) {
        dateArray[1] = '0' + dateArray[1];
        ConsoleLog({ 'GeneratePostEvcrHeader.dateArray[1]': dateArray[1] });
    }
    if (dateArray[2].length < 2) {
        dateArray[2] = '0' + dateArray[2];
        ConsoleLog({ 'GeneratePostEvcrHeader.dateArray[2]': dateArray[2] });
    }
    const date = dateArray[0] + dateArray[1] + dateArray[2];
    // YYYY-MM-DDTHH:mm:ss.sssZ
    const dateString = dateArray[0] +'-'+ dateArray[1] +'-'+ dateArray[2];

    //Handle time
    ConsoleLog({ 'GeneratePostEvcrHeader.time': trip.time });
    // trip.time = '9:8'
    const timeArray = trip.time.split(':');
    if (timeArray.length !== 2) {
        throw Error('time is invalid');
    }
    if (timeArray[0].length < 2) {
        timeArray[0] = '0' + timeArray[0];
        ConsoleLog({ 'GeneratePostEvcrHeader.timeArray[0]': timeArray[0] });
    }
    if (timeArray[1].length < 2) {
        timeArray[1] = '0' + timeArray[1];
        ConsoleLog({ 'GeneratePostEvcrHeader.timeArray[1]': timeArray[1] });
    }
    const time = timeArray[0] + timeArray[1] + '00';
    const timeString = timeArray[0] +':'+ timeArray[1] +':'+ '00'+ '.000';
    // YYYY-MM-DDTHH:mm:ss.sssZ
    //const dtUtc = new Date(dateString + 'T' + timeString + 'Z');
    const dtUtc = new Date(dateString + 'T' + timeString);
    ConsoleLog({ 'GeneratePostEvcrHeaderUtc.dtUtc.Utc': dtUtc.toUTCString() });
    ConsoleLog({ 'GeneratePostEvcrHeaderUtc.dtUtc.Local': dtUtc.toString() });
    const dateUtc = dtUtc.getUTCFullYear().toString() + PadLeftZero(dtUtc.getUTCMonth()+1) + PadLeftZero(dtUtc.getUTCDate());
    ConsoleLog({ 'GeneratePostEvcrHeaderUtc.dateUtc': dateUtc });
    const timeUtc = PadLeftZero(dtUtc.getUTCHours()) + PadLeftZero(dtUtc.getUTCMinutes()) + PadLeftZero(dtUtc.getUTCSeconds());
    ConsoleLog({ 'GeneratePostEvcrHeaderUtc.timeUtc': timeUtc });

    let otherTripId = '';
    if(trip.tripType === TripType.Other){
        otherTripId = dateUtc + NullToEmpty(trip.tractor) + trip.driver1;
    }
    let tripId ='';
    if(trip.tripType === TripType.LB){
        tripId=trip.lbsTripId;
    }
    if(trip.tripType === TripType.PG){
        tripId=trip.pgTripId;
    }
    if(trip.tripType === TripType.Other){
        tripId=otherTripId;
    }
    ConsoleLog({ 'GeneratePostEvcrHeaderUtc.tripId': tripId });
    // const time = trip.time.replace(':', '') + '00';
    //const evcrPost = new EvcrPost(trip.tripType, trip.tripType === TripType.LB ? trip.lbsTripId : trip.pgTripId, trip.prePost,''
    const evcrPost = new EvcrPost(trip.tripType, tripId, trip.prePost, trip.customPlantId
        , trip.driver1, trip.driver1Name
        , trip.driver2, NullToEmpty(trip.tractor), trip.thirdParty ? consts.STR_THIRD_PARTY_TRUE : "", NullToEmpty(trip.chassis)
        , NullToEmpty(trip.vessel), trip.odometer, dateUtc, timeUtc
        ,trip.isTractorThirdParty ? 'X' : ''
        ,trip.isChassisThirdParty ? 'X' : ''
        ,trip.isVesselThirdParty ? 'X' : ''
        ,trip.country, trip.tractor3rdPartyVehType,
        trip.chassis3rdPartyVehType,
        trip.vessel3rdPartyVehType)
    return evcrPost;
};

export const GeneratePostEvcrData = (trip: TripInfo, section: IQuestionSection) => {
    const evcrPost = GeneratePostEvcrHeaderUtc(trip);
    // const isChina =trip.country==='CN'?true:false;
    // if(isChina){
    //     GenerateSubSectionQuestionsForChina(true,evcrPost.eVCRRecordItemSet, section);
    // }
    // else{
    //     GenerateSubSectionQuestions(evcrPost.eVCRRecordItemSet, section);
    // }
    
    //GenerateSubSectionQuestions(evcrPost.eVCRRecordItemSet, section);        
    GenerateSubSectionQuestionsRegard3rdParty(trip, evcrPost.eVCRRecordItemSet, section);
    return evcrPost;
};

export const GeneratePostEvcrDataSign = (trip: TripInfo, section: IQuestionSection) => {
    //const evcrPostSign = GeneratePostEvcrSignHeaderUtc(trip);
    const evcrPostSign = GeneratePostEvcrSignHeader(trip);
    GenerateSubSectionQuestions(evcrPostSign.eVCRRecordItemSet, section);
    return evcrPostSign;
};


export const GeneratePostEvcrSignHeader = (trip: TripInfo) => {
    // const postDateTime = new Date((trip.date + ' ' + trip.time).replace(/-/g, "/")).toISOString().substring(0, 19);
    // const date = trip.date.replace(/-/g, '');
    // trip.date = '2022-4-2';
    const dateArray = trip.date.split('-');
    if (dateArray.length !== 3) {
        throw Error('Date is invalid');
    }
    if (dateArray[1].length < 2) {
        dateArray[1] = '0' + dateArray[1];
        ConsoleLog({ 'GeneratePostEvcrHeader.dateArray[1]': dateArray[1] });
    }
    if (dateArray[2].length < 2) {
        dateArray[2] = '0' + dateArray[2];
        ConsoleLog({ 'GeneratePostEvcrHeader.dateArray[2]': dateArray[2] });
    }
    const date = dateArray[0] + dateArray[1] + dateArray[2];
    // YYYY-MM-DDTHH:mm:ss.sssZ
    //const dateString = dateArray[0] +'-'+ dateArray[1] +'-'+ dateArray[2];

    //Handle time
    ConsoleLog({ 'GeneratePostEvcrHeader.time': trip.time });
    // trip.time = '9:8'
    const timeArray = trip.time.split(':');
    if (timeArray.length !== 2) {
        throw Error('time is invalid');
    }
    if (timeArray[0].length < 2) {
        timeArray[0] = '0' + timeArray[0];
        ConsoleLog({ 'GeneratePostEvcrHeader.timeArray[0]': timeArray[0] });
    }
    if (timeArray[1].length < 2) {
        timeArray[1] = '0' + timeArray[1];
        ConsoleLog({ 'GeneratePostEvcrHeader.timeArray[1]': timeArray[1] });
    }
    const time = timeArray[0] + timeArray[1] + '00';
    //const timeString = timeArray[0] +':'+ timeArray[1] +':'+ '00'+ '.000';
    // YYYY-MM-DDTHH:mm:ss.sssZ
    //const dtUtc = new Date(dateString + 'T' + timeString + 'Z');
    //const dtUtc = new Date(dateString + 'T' + timeString);
    //ConsoleLog({ 'GeneratePostEvcrHeaderUtc.dtUtc.Utc': dtUtc.toUTCString() });
    //ConsoleLog({ 'GeneratePostEvcrHeaderUtc.dtUtc.Local': dtUtc.toString() });
    //const dateUtc = dtUtc.getUTCFullYear().toString() + PadLeftZero(dtUtc.getUTCMonth()+1) + PadLeftZero(dtUtc.getUTCDate());
    //ConsoleLog({ 'GeneratePostEvcrHeaderUtc.dateUtc': dateUtc });
    //const timeUtc = PadLeftZero(dtUtc.getUTCHours()) + PadLeftZero(dtUtc.getUTCMinutes()) + PadLeftZero(dtUtc.getUTCSeconds());
    //ConsoleLog({ 'GeneratePostEvcrHeaderUtc.timeUtc': timeUtc });
    let otherTripId = '';
    if(trip.tripType === TripType.Other){
        otherTripId = date + NullToEmpty(trip.tractor) + trip.driver1;
    }
    let tripId ='';
    if(trip.tripType === TripType.LB){
        tripId=trip.lbsTripId;
    }
    if(trip.tripType === TripType.PG){
        tripId=trip.pgTripId;
    }
    if(trip.tripType === TripType.Other){
        tripId=otherTripId;
    }
    ConsoleLog({ 'GeneratePostEvcrSignHeader.tripId': tripId });
    //const evcrPost = new PrintFormPost(trip.tripType, trip.tripType === TripType.LB ? trip.lbsTripId : trip.pgTripId, trip.prePost,trip.plantId
    const evcrPost = new PrintFormPost(trip.tripType, tripId, trip.prePost,trip.plantId
        , trip.driver1, trip.driver1Name
        , trip.driver2, NullToEmpty(trip.tractor), trip.thirdParty ? consts.STR_THIRD_PARTY_TRUE : ""
        , NullToEmpty(trip.chassis), NullToEmpty(trip.vessel), trip.odometer, date, time
        , trip.signImage,trip.country,trip.language
        , trip.plantName,trip.overviewImage
        ,window.location.href
        ,trip.customPlantId)
    return evcrPost;
};

export const GeneratePostEvcrSignHeaderUtc = (trip: TripInfo) => {
    // const postDateTime = new Date((trip.date + ' ' + trip.time).replace(/-/g, "/")).toISOString().substring(0, 19);
    // const date = trip.date.replace(/-/g, '');
    // trip.date = '2022-4-2';
    const dateArray = trip.date.split('-');
    if (dateArray.length !== 3) {
        throw Error('Date is invalid');
    }
    if (dateArray[1].length < 2) {
        dateArray[1] = '0' + dateArray[1];
        ConsoleLog({ 'GeneratePostEvcrHeader.dateArray[1]': dateArray[1] });
    }
    if (dateArray[2].length < 2) {
        dateArray[2] = '0' + dateArray[2];
        ConsoleLog({ 'GeneratePostEvcrHeader.dateArray[2]': dateArray[2] });
    }
    const date = dateArray[0] + dateArray[1] + dateArray[2];
    // YYYY-MM-DDTHH:mm:ss.sssZ
    const dateString = dateArray[0] +'-'+ dateArray[1] +'-'+ dateArray[2];

    //Handle time
    ConsoleLog({ 'GeneratePostEvcrHeader.time': trip.time });
    // trip.time = '9:8'
    const timeArray = trip.time.split(':');
    if (timeArray.length !== 2) {
        throw Error('time is invalid');
    }
    if (timeArray[0].length < 2) {
        timeArray[0] = '0' + timeArray[0];
        ConsoleLog({ 'GeneratePostEvcrHeader.timeArray[0]': timeArray[0] });
    }
    if (timeArray[1].length < 2) {
        timeArray[1] = '0' + timeArray[1];
        ConsoleLog({ 'GeneratePostEvcrHeader.timeArray[1]': timeArray[1] });
    }
    const time = timeArray[0] + timeArray[1] + '00';
    const timeString = timeArray[0] +':'+ timeArray[1] +':'+ '00'+ '.000';
    // YYYY-MM-DDTHH:mm:ss.sssZ
    //const dtUtc = new Date(dateString + 'T' + timeString + 'Z');
    const dtUtc = new Date(dateString + 'T' + timeString);
    ConsoleLog({ 'GeneratePostEvcrHeaderUtc.dtUtc.Utc': dtUtc.toUTCString() });
    ConsoleLog({ 'GeneratePostEvcrHeaderUtc.dtUtc.Local': dtUtc.toString() });
    const dateUtc = dtUtc.getUTCFullYear().toString() + PadLeftZero(dtUtc.getUTCMonth()+1) + PadLeftZero(dtUtc.getUTCDate());
    ConsoleLog({ 'GeneratePostEvcrHeaderUtc.dateUtc': dateUtc });
    const timeUtc = PadLeftZero(dtUtc.getUTCHours()) + PadLeftZero(dtUtc.getUTCMinutes()) + PadLeftZero(dtUtc.getUTCSeconds());
    ConsoleLog({ 'GeneratePostEvcrHeaderUtc.timeUtc': timeUtc });

    // const time = trip.time.replace(':', '') + '00';
    const evcrPost = new PrintFormPost(trip.tripType, trip.tripType === TripType.LB ? trip.lbsTripId : trip.pgTripId, trip.prePost,trip.plantId
        , trip.driver1, trip.driver1Name
        , trip.driver2, NullToEmpty(trip.tractor), trip.thirdParty ? consts.STR_THIRD_PARTY_TRUE : ""
        , NullToEmpty(trip.chassis), NullToEmpty(trip.vessel), trip.odometer, dateUtc, timeUtc
        , trip.signImage,trip.country,trip.language
        , trip.plantName,trip.overviewImage
        ,window.location.href
        ,trip.customPlantId)
    return evcrPost;
};

export const GeneratePostEvcrImages = (trip: TripInfo, section: IQuestionSection) => {
    const evcrPost = GeneratePostEvcrHeader(trip);
    GenerateSubSectionQuestions(evcrPost.eVCRRecordItemSet, section, true);
    return evcrPost;
};

//every defective question has entire post request.
export const GeneratePostEvcrImageArray = (imgeArray: IEvcrPost[], trip: TripInfo, section: IQuestionSection) => {
    section.regularQuestionCollection.forEach(subSection => {
        subSection.questions.filter(item => item.notificationIsNeeded === true && !item.nonFm).forEach(que => {
            addAttachments(que, imgeArray, trip);
        });

    });
    if (section.containAdditionalQuestion) {
        section.additionalQuestionCollection.forEach(subSection => {
            subSection.questions.filter(item => item.notificationIsNeeded === true && !item.nonFm).forEach(que => {
                addAttachments(que, imgeArray, trip);
            });
        });
    }
};

const addAttachments = (que: IQuestion, imgeArray: IEvcrPost[], trip: TripInfo) => {
    const attachments = GenerateQuestionWithPic(que);
    attachments.forEach(attachment => {
        const evcrPost = GeneratePostEvcrHeader(trip);
        evcrPost.eVCRRecordItemSet.push(attachment);
        imgeArray.push(evcrPost)
    });
}
// export const GenerateSubSectionQuestionsOld = (questionPost: IQuestionPost[], subSection: IQuestionSection) => {
//     subSection.regularQuestionCollection.forEach(subSection => {
//         subSection.questions.forEach(que => {
//             questionPost.push(GenerateQuestionOneAttachment(que));
//         });

//     });
//     if (subSection.containAdditionalQuestion) {
//         subSection.additionalQuestionCollection.forEach(subSection => {
//             subSection.questions.forEach(que => {
//                 questionPost.push(GenerateQuestionOneAttachment(que));
//             });

//         });
//     }
// }

export const GenerateSubSectionQuestions = (questionPost: IQuestionPost[], subSection: IQuestionSection, imageNeeded: boolean = false) => {
    // only need the questions which are defective
    if (imageNeeded) {
        subSection.regularQuestionCollection.forEach(subSection => {
            subSection.questions.filter(item => item.notificationIsNeeded === true).forEach(que => {
                questionPost.push(...GenerateQuestionWithPic(que));
            });

        });
        if (subSection.containAdditionalQuestion) {
            subSection.additionalQuestionCollection.forEach(subSection => {
                subSection.questions.filter(item => item.notificationIsNeeded === true).forEach(que => {
                    questionPost.push(...GenerateQuestionWithPic(que));
                });

            });
        }
    } else {
        //All questions 
        subSection.regularQuestionCollection.forEach(subSection => {
            subSection.questions.forEach(que => {
                questionPost.push(GenerateQuestionWithoutPic(que));
            });

        });
        if (subSection.containAdditionalQuestion) {
            subSection.additionalQuestionCollection.forEach(subSection => {
                subSection.questions.forEach(que => {
                    questionPost.push(GenerateQuestionWithoutPic(que));
                });

            });
        }
    }
}

export const GenerateSubSectionQuestionsRegard3rdParty = (trip: TripInfo, questionPost: IQuestionPost[], subSection: IQuestionSection, imageNeeded: boolean = false) => {
    // only need the questions which are defective
    if (imageNeeded) {
        subSection.regularQuestionCollection.forEach(subSection => {
            subSection.questions.filter(item => item.notificationIsNeeded === true).forEach(que => {
                questionPost.push(...GenerateQuestionWithPicRegard3rdParty(trip,que));
            });

        });
        if (subSection.containAdditionalQuestion) {
            subSection.additionalQuestionCollection.forEach(subSection => {
                subSection.questions.filter(item => item.notificationIsNeeded === true).forEach(que => {
                    questionPost.push(...GenerateQuestionWithPicRegard3rdParty(trip,que));
                });

            });
        }
    } else {
        //All questions 
        subSection.regularQuestionCollection.forEach(subSection => {
            subSection.questions.forEach(que => {
                questionPost.push(GenerateQuestionWithoutPicRegard3rdParty(trip,que));
            });

        });
        if (subSection.containAdditionalQuestion) {
            subSection.additionalQuestionCollection.forEach(subSection => {
                subSection.questions.forEach(que => {
                    questionPost.push(GenerateQuestionWithoutPicRegard3rdParty(trip,que));
                });

            });
        }
    }
}

export const GenerateSubSectionQuestionsForChina = (isChina: boolean, questionPost: IQuestionPost[], subSection: IQuestionSection, imageNeeded: boolean = false) => {
    // only need the questions which are defective
    if (imageNeeded) {
        subSection.regularQuestionCollection.forEach(subSection => {
            subSection.questions.filter(item => item.notificationIsNeeded === true).forEach(que => {
                questionPost.push(...GenerateQuestionWithPicForChina(isChina,que));
            });

        });
        if (subSection.containAdditionalQuestion) {
            subSection.additionalQuestionCollection.forEach(subSection => {
                subSection.questions.filter(item => item.notificationIsNeeded === true).forEach(que => {
                    questionPost.push(...GenerateQuestionWithPicForChina(isChina,que));
                });

            });
        }
    } else {
        //All questions 
        subSection.regularQuestionCollection.forEach(subSection => {
            subSection.questions.forEach(que => {
                questionPost.push(GenerateQuestionWithoutPicForChina(isChina,que));
            });

        });
        if (subSection.containAdditionalQuestion) {
            subSection.additionalQuestionCollection.forEach(subSection => {
                subSection.questions.forEach(que => {
                    questionPost.push(GenerateQuestionWithoutPicForChina(isChina,que));
                });

            });
        }
    }
}

// export const GenerateQuestionOld = (que: IQuestion) => {
//     const img = (IsNullOrEmpty(que.fileName) || IsNullOrEmpty(que.attachmentBase64)) ? '' : que.attachmentBase64;
//     return new QuestionPost(que.equipmentID, que.codeGroup, que.code, que.charact, que.isReqular ? que.subSectionId : "", que.sequenceId, que.notificationIsNeeded, que.answer, que.issueDesc, que.desc, img);
// }

// export const GenerateQuestionOneAttachment = (que: IQuestion, imageNeeded: boolean = false) => {
//     const img = imageNeeded ? que.attachmentBase64 : "";
//     return new QuestionPost(que.equipmentID, que.codeGroup, que.code, que.charact, que.isReqular ? que.subSectionId : "", que.isReqular ? que.sequenceId : "", que.notificationIsNeeded, que.answer, que.issueDesc, que.desc, img);
// }

export const GenerateQuestionWithoutPicRegard3rdParty = (trip: TripInfo, que: IQuestion) => {
    let VehicleId3rd = '';
    if(IsNullOrEmpty(que.equipmentID)){
        // if(que.sectionType == QuestionSectionType.Tractor){
        //     VehicleId3rd = trip.tractor
        // }
        // if(que.sectionType == QuestionSectionType.Chassis){
        //     VehicleId3rd=trip.chassis
        // }
        // if(que.sectionType == QuestionSectionType.Vessel){
        //     VehicleId3rd=trip.vessel
        // }
        if(que.vehicleType == TractorThirdPartyCategory.TRACTOR 
            || que.vehicleType == TractorThirdPartyCategory.RIGID_PGG_KNUCKLE_CRANE_TRUCK
            || que.vehicleType == TractorThirdPartyCategory.RIGID_PGG_TRUCK
            || que.vehicleType == TractorThirdPartyCategory.RIGID_TANKER){
            VehicleId3rd = trip.tractor
        }
        if(que.vehicleType == ChassisThirdPartyCategory.CHASSIS_TRAILER
            || que.vehicleType == ChassisThirdPartyCategory.PGG_CHASSIS){
            VehicleId3rd=trip.chassis
        }
        if(que.vehicleType == VesselThirdPartyCategory.LB_MB_ISO_TANKER
            || que.vehicleType == VesselThirdPartyCategory.TUBE_SKID){
            VehicleId3rd=trip.vessel
        }
    }
    return new QuestionPost(que.equipmentID, VehicleId3rd, que.codeGroup, que.code, que.charact
        , que.isReqular ? que.subSectionId : que.originalSubSectionId, que.isReqular ? que.sequenceId : que.originalSequenceId
        , VehicleId3rd===''?que.notificationIsNeeded:false, que.answer, que.answer === "Defective"?que.issueDesc:"", que.desc, que.hardStop?"X":"", "");
}

export const GenerateQuestionWithPicRegard3rdParty = (trip: TripInfo, que: IQuestion) => {
    const questPosts: IQuestionPost[] = [];
    let VehicleId3rd = '';
    if(IsNullOrEmpty(que.equipmentID)){
        // if(que.sectionType == QuestionSectionType.Tractor){
        //     VehicleId3rd = trip.tractor
        // }
        // if(que.sectionType == QuestionSectionType.Chassis){
        //     VehicleId3rd=trip.chassis
        // }
        // if(que.sectionType == QuestionSectionType.Vessel){
        //     VehicleId3rd=trip.vessel
        // }
        if(que.vehicleType == TractorThirdPartyCategory.TRACTOR 
            || que.vehicleType == TractorThirdPartyCategory.RIGID_PGG_KNUCKLE_CRANE_TRUCK
            || que.vehicleType == TractorThirdPartyCategory.RIGID_PGG_TRUCK
            || que.vehicleType == TractorThirdPartyCategory.RIGID_TANKER){
            VehicleId3rd = trip.tractor
        }
        if(que.vehicleType == ChassisThirdPartyCategory.CHASSIS_TRAILER
            || que.vehicleType == ChassisThirdPartyCategory.PGG_CHASSIS){
            VehicleId3rd=trip.chassis
        }
        if(que.vehicleType == VesselThirdPartyCategory.LB_MB_ISO_TANKER
            || que.vehicleType == VesselThirdPartyCategory.TUBE_SKID){
            VehicleId3rd=trip.vessel
        }
    }
    que.attachments.filter(item => !IsNullOrEmpty(item.attachmentBase64)).forEach((file: IAttachment) => {
        questPosts.push(new QuestionPost(que.equipmentID, VehicleId3rd, que.codeGroup, que.code, que.charact
            , que.isReqular ? que.subSectionId : que.originalSubSectionId, que.isReqular ? que.sequenceId : que.originalSequenceId
            , VehicleId3rd===''?que.notificationIsNeeded:false, que.answer, que.answer === "Defective"?que.issueDesc:"", que.hardStop?"X":"",que.desc, file.attachmentBase64));
    }
    );
    return questPosts;
}

export const GenerateQuestionWithoutPic = (que: IQuestion) => {
    return new QuestionPost(que.equipmentID, "", que.codeGroup, que.code, que.charact, que.isReqular ? que.subSectionId : que.originalSubSectionId, que.isReqular ? que.sequenceId : que.originalSequenceId, que.notificationIsNeeded, que.answer, que.answer === "Defective"?que.issueDesc:"", que.desc, que.hardStop?"X":"", "");
}

export const GenerateQuestionWithPic = (que: IQuestion) => {
    const questPosts: IQuestionPost[] = [];
    que.attachments.filter(item => !IsNullOrEmpty(item.attachmentBase64)).forEach((file: IAttachment) => {
        questPosts.push(new QuestionPost(que.equipmentID, "",que.codeGroup, que.code, que.charact, que.isReqular ? que.subSectionId : que.originalSubSectionId, que.isReqular ? que.sequenceId : que.originalSequenceId, que.notificationIsNeeded, que.answer, que.answer === "Defective"?que.issueDesc:"", que.hardStop?"X":"",que.desc, file.attachmentBase64));
    }
    );
    return questPosts;
}
export const GenerateQuestionWithoutPicForChina = (isChina: boolean, que: IQuestion) => {
    return new QuestionPost(que.equipmentID, "",que.codeGroup, que.code, que.charact, que.isReqular ? que.subSectionId : que.originalSubSectionId, que.isReqular ? que.sequenceId : que.originalSequenceId
        , isChina?false:que.notificationIsNeeded, que.answer, que.answer === "Defective"?que.issueDesc:"", que.desc, que.hardStop?"X":"", "");
}

export const GenerateQuestionWithPicForChina = (isChina: boolean, que: IQuestion) => {
    const questPosts: IQuestionPost[] = [];
    que.attachments.filter(item => !IsNullOrEmpty(item.attachmentBase64)).forEach((file: IAttachment) => {
        questPosts.push(new QuestionPost(que.equipmentID, "",que.codeGroup, que.code, que.charact, que.isReqular ? que.subSectionId : que.originalSubSectionId, que.isReqular ? que.sequenceId : que.originalSequenceId
            , isChina?false:que.notificationIsNeeded, que.answer, que.answer === "Defective"?que.issueDesc:"", que.hardStop?"X":"",que.desc, file.attachmentBase64));
    }
    );
    return questPosts;
}