<template>
    <div v-if="isVisible" @click.stop="" class="modal-overlay" >
      <div class="modal">
        <div class="modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="body">Default Body</slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button @click="close"></button>
          </slot>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }
  </script>

  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 320px;
    max-width: 100%;
    backdrop-filter: unset;
  }
  .modal-header, .modal-footer {
    padding: 10px 0;
  }

  .modal-footer {
    float: right;
    display: flex;
    justify-content: flex-end;
  }
  .modal-body {
    padding: 20px 0;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0;
  }
  .modal-footer > div {
    display: flex;
    justify-content: flex-end;
  }
  </style>