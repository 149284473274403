export enum StoreGetter {
    emEvcr = 'evcrModule/evcr',
    emTripInfo = 'evcrModule/tripInfo',
    emNearbyPlant = 'evcrModule/nearbyPlant',
    emVehicles = 'evcrModule/vehicles',
    emMaintainNotifications = 'evcrModule/maintainNotifications',
    emQuestionTractor = 'evcrModule/questionTractor',
    emQuestionChassis = 'evcrModule/questionChassis',
    emQuestionVessel = 'evcrModule/questionVessel',
    emQuestions = 'evcrModule/questions',
    emHasDefects = 'evcrModule/hasDefects',
    emHasTopDefects = 'evcrModule/hasTopDefects',
    emIsEdit = 'evcrModule/isEdit',
    emQuestionFilterChanged = 'evcrModule/questionFilterChanged',
    temEditable = 'tmpEvcrModule/editable',

    lemLastEvcrTripInfo = "lastEvcrModule/lastEvcrTrip",
    lemLastEvcrTripInfoByDriver = "lastEvcrModule/lastEvcrTripByDriver",
    lemGetTractor = "lastEvcrModule/getTractor",
    lemQuestions = "lastEvcrModule/questions",

    umUserProfile = 'userModule/userProfile',
    umUserId = 'userModule/userId',
    umUserName = 'userModule/userName',
    umUserCountryCode = 'userModule/userCountryCode',
}

export enum StoreAction {
    emInitialData = 'evcrModule/initialData',
    emInitialTripData = 'evcrModule/initialTripData',
    emGetFive = 'evcrModule/getFive',
    // emInitializeEvcrData = 'evcrModule/initializeEvcData',
    emGetNearbyPlant = 'evcrModule/getNearbyPlant',
    emGetPlant = 'evcrModule/getPlant',
    emGetVehicle = 'evcrModule/getVehicle',
    emGetMaintainNotification = 'evcrModule/getMaintainNotification',
    emGetQuestions = 'evcrModule/getQuestions',
    emPostNewEvcr = 'evcrModule/postNewEvcr',
    emPostNewEvcrSign = 'evcrModule/postNewEvcrSign',

    emValidateNewEvcr = 'evcrModule/validateNewEvcr',

    lvmGetLastEvcr = 'lastEvcrModule/getLastEvcr',
    lvmGetLastEvcrByDriver = 'lastEvcrModule/getLastEvcrByDriver',
    temInitializeEvcrData = 'tmpEvcrModule/initializeEvcData',
    umLogin = 'userModule/login',
}

export enum StoreMutation {
    StartLoading = 'startLoading',
    EndLoading = 'endLoading',
    emSubmitFailed = "evcrModule/submitFailed",
    emSubmitSuccess = "evcrModule/submitSuccess",
    emResetEvcr = "evcrModule/resetEvcr",
    emClearNearByData = "evcrModule/clearNearByData",
    emUpdateTripInfo = "evcrModule/updateTripInfo",
    emClearTripId = "evcrModule/clearTripId",
    emUpdateIsEdit = "evcrModule/updateIsEdit",
    emUpdateQuestionTractor = "evcrModule/updateQuestionTractor",
    emUpdateQuestionChassis = "evcrModule/updateQuestionChassis",
    emUpdateQuestionVessel = "evcrModule/updateQuestionVessel",
    emUpdateQuestions = "evcrModule/updateQuestions",
    emUpdateQuestion = "evcrModule/updateQuestion",
    emInitializeState = "evcrModule/initializeState",
    amSubmitFailed = "evcrModule/submitFailed",
    emUpdateLastEvcrByDriver = "lastEvcrModule/updateLastEvcrByDriver",

    lemResetLastEvcr = "lastEvcrModule/resetLastVcr",
    lemUpdateTractor = "lastEvcrModule/updateTractor",
    temSetEditable = 'tmpEvcrModule/setEditable',

}