<template>
  <v-card justify="center">
    <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- <v-toolbar-title>{{ Text("ml_114669", "Scan") + " " }}</v-toolbar-title> -->
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark text @click="close" v-t="'ml_114657'"> Okay </v-btn>
          </v-toolbar-items> -->
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-img
          :src="VehiclePath(questions.imageName)"
          ref="vehiclePic"
          class="div-align-center"
          :height="photoHeight"
          :width="photoWidth"
          @load="loadedPic"
        ></v-img>
        <!-- :height="picHeight()"
          :width="picWidth()" -->
        <p class="red-error" v-show="errorMsg.length > 0">{{ errorMsg }}</p>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import { QuestionSection } from "@/models/question-section";
import { ConsoleLog, Timeout, Text } from "@/utility";
import Vue from "vue";
import { GetPicSize, VehiclePath } from "@/utility/ui-utility";

export default Vue.extend({
  components: {},
  props: {
    section: { type: Object, default: {} },
    // openDialog: { type: Boolean, default: false },
  },
  data() {
    return {
      showDialog: false,
      errorMsg: "",
      isValid: { type: Boolean, default: undefined },
      questions: { type: QuestionSection, default: {} },
      photoHeight: 0,
      photoWidth: 0,
    };
  },
  computed: {
    validationSuccess(): boolean {
      return (this as any).isValid === true;
    },
  },
  methods: {
    Text,
    updateShowDialog(status: boolean) {
      const self = this as any;
      self.showDialog = status;
    },

    VehiclePath,
    close(): void {
      (this as any).showDialog = false;
    },
    loadedPic(imageEvent: any) {
      // ConsoleLog({ loadPic: this.$refs.vehiclePic, imageEvent: imageEvent });
      const img = this.$refs.vehiclePic as any;
      if (img.image) {
        this.photoWidth = img.image.width;
        this.photoHeight = img.image.height;
        ConsoleLog({
          ZoomPhotoWidth: this.photoWidth,
          ZoomPhotoHeight: this.photoHeight,
        });
      }
    },
    picWidth() {
      const self = this as any;
      return GetPicSize(self.questions.imageName).width;
    },
    picHeight() {
      const self = this as any;
      return GetPicSize(self.questions.imageName).height;
    },
  },
  beforeMount() {
    const self = this as any;
    ConsoleLog({ "ZoomIn.questions": self.section });
    self.questions = self.section;
    // self.showDialog = self.openDialog;
  },
});
</script>
