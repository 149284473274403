<template>
  <v-container>
    <v-row
      v-show="
        recordQuestionSummaryData.containDefect ||
        recordQuestionSummaryData.additionalQuestionContainDefect
      "
    >
      <!-- <v-card-title>{{ recordQuestionSummaryData.title }}</v-card-title> -->
      <!-- <div class="alignCenter fontBolder">{{ recordQuestionSummaryData.title }}</div> -->
      <v-card-text> 
        <!-- v-card-text is needed in order to control the picture's size -->
        <template v-if="recordQuestionSummaryData.containDefect">
          <RecordQuestionSummaryRegular
            v-for="(
              questionRegular, questionRegularIndex
            ) in recordQuestionSummaryData.regularQuestionCollection"
            :key="questionRegularIndex"
            :record-question-summary-regular-data="questionRegular"
          />
        </template>
        <template v-if="recordQuestionSummaryData.additionalQuestionContainDefect">
          <RecordQuestionSummaryAdditional
            v-for="(
              questionAdditional, questionAdditionalIndex
            ) in recordQuestionSummaryData.additionalQuestionCollection"
            :key="questionAdditionalIndex + 1000"
            :record-question-summary-additional-data="questionAdditional"
          />
        </template>
      </v-card-text>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
// in full builds helpers are exposed as Vuex.mapState
import { mapState } from "vuex";
import RecordQuestionSummaryRegular from "./RecordQuestionSummaryRegular.vue";
import RecordQuestionSummaryAdditional from "./RecordQuestionSummaryAdditional.vue";
import { ConsoleLog, CloneObj } from "@/utility";
// import HomePage from "../components/HomePage.vue";

export default Vue.extend({
  components: {
    // RecordQuestionHead,
    // RecordQuestionRegular,
    // RecordQuestionAdditional,
    RecordQuestionSummaryRegular,
    RecordQuestionSummaryAdditional,
    // HomePage,
  },
  data() {
    return {};
  },
  props: {
    recordQuestionSummaryData: {
      type: Object,
    },
  },
  computed: {},
  methods: {
    submitOrder() {
      this.$router.push({ name: "thankyou" });
    },
  },
  mounted() {
    ConsoleLog([
      "RecordQuestionSummaryItem.recordQuestionSummaryData",
      CloneObj(this.recordQuestionSummaryData),
    ]);
  },
});
</script>

<style></style>
