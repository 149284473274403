<template>
  <!-- <v-container> -->
    <v-row>
      <!-- <v-card-subtitle>{{ recordQuestionSummaryAdditionalData.subSectionId }}</v-card-subtitle> -->
      <v-card-text>
        <RecordQuestionSummaryRegularItem
          v-for="(
            question, questionIndex
          ) in recordQuestionSummaryAdditionalData.questions"
          :key="questionIndex"
          :record-question-summary-regular-item-data="question"
        />
      </v-card-text>
    </v-row>
  <!-- </v-container> -->
</template>
<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
// import RecordQuestionHead from './RecordQuestionHead.vue'
// import RecordQuestionRegular from './RecordQuestionRegular.vue'
import RecordQuestionSummaryRegularItem from "./RecordQuestionSummaryRegularItem.vue";
// import HomePage from "../components/HomePage.vue";

export default Vue.extend({
  components: {
    // RecordQuestionHead,
    // RecordQuestionRegular,
    // RecordQuestionAdditional,
    RecordQuestionSummaryRegularItem,
    // HomePage,
  },
  data() {
    return {
    };
  },
  props: {
    recordQuestionSummaryAdditionalData: {
      type: Object,
    },
  },
  computed: {},
  methods: {
    // next() {
    //   this.step += 1
    // },
    // previous() {
    //   this.step -= 1
    // },
    submitOrder() {
      this.$router.push({ name: "thankyou" });
    },
  },
});
</script>

<style></style>
