<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title v-t="'ml_114655'">Defect Summary</v-card-title>
          <template
            v-if="questions.containDefect || questions.additionalQuestionContainDefect"
          >
            <v-card-text>
              <RecordQuestionSummaryItem :record-question-summary-data="questions" />
            </v-card-text>
          </template>
          <v-card-text
            v-if="!(questions.containDefect || questions.additionalQuestionContainDefect)"
            v-t="'ml_114927'"
          >
            No Defect
          </v-card-text>
          <v-card-text v-if="errorMsg.length > 0">
            <div class="red-error">{{ errorMsg }}</div>
          </v-card-text>

          <v-card-actions>
            <!-- <v-btn color="primary" @click="GoBack($router)" v-t="'ml_114638'">
              Back
            </v-btn> -->
            <v-btn color="primary" @click="GoOverview($router)" v-t="'ml_114646'">
              eVCR Overview
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="goNext()" v-t="'ml_114656'"> Submit </v-btn>
          </v-card-actions>
          <v-card-actions v-if="TestMode()">
            <v-btn color="primary" @click="submitAll()"> AllNoWait </v-btn>
            <v-btn color="primary" @click="submitAllWait()"> All Wait </v-btn>
            <v-btn color="primary" @click="submitOne()"> 1by1 </v-btn>
            <v-btn color="primary" @click="submitParallel()"> Parallel </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import RecordQuestionSummaryItem from "../components/RecordQuestionSummaryItem.vue";
import {
  GoHome,
  GoBack,
  GoTripInfo,
  GoCondition,
  GoQuestionChassis,
  GoQuestionVessel,
  GoSummary,
  GoOverview,
  Confirm,
  TestMode,
  RemoveStorageEvcr,
} from "@/utility/ui-utility";
import { ImgeApiMode, PageName, SubmitStatus } from "@/models/enums";
import { StoreAction, StoreGetter, StoreMutation } from "@/store/store-mapping";
import { ConsoleLog, IsNullOrEmpty, Text } from "@/utility";
import { mapGetters } from "vuex";



export default Vue.extend({
  props: {},
  components: {
    // RecordQuestionHead,
    // RecordQuestionRegular,
    // RecordQuestionAdditional,
    RecordQuestionSummaryItem,
    // HomePage,
  },
  data() {
    return {
      errorMsg: "",
    };
  },
  computed: {
    ...mapGetters({
      questions: StoreGetter.emQuestions,
      hasDefects: StoreGetter.emHasDefects,
      hasTopDefects: StoreGetter.emHasTopDefects,
    }),
  },
  methods: {
    Text,
    GoOverview,
    GoBack,
    TestMode,
    async submitAll() {
      const isGood = await this.submitTest(ImgeApiMode.AllNotWait);
    },
    async submitAllWait() {
      const isGood = await this.submitTest(ImgeApiMode.AllWait);
    },
    async submitParallel() {
      const isGood = await this.submitTest(ImgeApiMode.Parallel);
    },
    async submitOne() {
      const isGood = await this.submitTest(ImgeApiMode.One);
    },
    async goNext() {
      ConsoleLog("defect summary page, enter go next method");
      const self = this as any;
      self.errorMsg = "";
      //validation
      // if (false) {
      //   return;
      // }
      if (this.hasTopDefects) {
        ConsoleLog("defect summary page, enter go next method, go has defect branch");
        this.$router.push({
          name: PageName.RecordQuestionSubmitNotice,
        });
        ConsoleLog("defect summary page, enter go next method, go has defect branch, complete");
      } else {
        //if (!Confirm(Text("ml_114678", "Are you sure to submit the eVCR ?"))) {
        //  return;
        //}
        ConsoleLog("defect summary page, enter go next method, go has no defect branch");
        this.$router.push({
          name: PageName.RecordQuestionSubmitSign,
        });
        ConsoleLog("defect summary page, enter go next method, go has no defect branch, complete");

        //const isGood = await this.submit(ImgeApiMode.One);
        //if (isGood) {
        //  this.$router.push({
        //    name: PageName.RecordQuestionSubmitResult,
        //    params: { action: SubmitStatus.Success },
        //  });
        //}

      }
    },

    async submitTest(imageApiMode: ImgeApiMode) {
      this.errorMsg = "";
      //submit request
      const self = this as any;
      let isOk = false;
      ConsoleLog("eVCR submitTest at question page");
      isOk = await self.$store.dispatch(StoreAction.emPostNewEvcr, {
        imageApiMode,
        longitude: "2",
      });
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.errorMsg = this.$store.state.evcrModule.errorMsg;
      }
      return isOk;
    },

    async submit(imageApiMode: ImgeApiMode) {
      //submit request
      const self = this as any;
      let isOk = false;
      ConsoleLog("eVCR sumbit at question page");
      isOk = await self.$store.dispatch(StoreAction.emPostNewEvcr, {
        imageApiMode,
        longitude: "2",
      });
      if (this.$store.state.evcrModule.errorMsg.length > 0) {
        this.errorMsg = this.$store.state.evcrModule.errorMsg;
      }
      if (isOk) {
        this.$store.commit(StoreMutation.emResetEvcr);
        RemoveStorageEvcr();
      }
      return isOk;
    },



    async mounted() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
});
</script>

<style></style>
